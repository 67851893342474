import { Segmented, Table } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div``;

export const Tabs = styled(Segmented)`
  * {
    box-sizing: border-box;
  }

  &.ant-segmented {
    width: 100%;
    border-block: 1px solid ${colors.border};
    border-radius: 0px;
    padding: 0px;

    .ant-segmented-thumb {
      border-bottom: 2px solid ${colors.main} !important;
      height: 100%;
      border-radius: 0px;
    }

    & .ant-segmented-item {
      border-radius: 0px;
      flex: 1;
      padding: 0px;

      & .ant-segmented-item-label {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 0px;
        // padding-block: 13px;

        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        // background-color: #fff;
      }
    }

    & .ant-segmented-item-selected {
      & .ant-segmented-item-label {
        color: ${colors.main};
        border-bottom: 2px solid ${colors.main};
      }
    }
  }
`;
