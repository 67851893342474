import dayjs from 'dayjs';

export const getCaseNo = (case1, case2, case3) => {
  if (case1 === 1 && case2 === 1 && case3 === 1) {
    return 1;
  }
  if (case1 === 1 && case2 === 2 && case3 === 1) {
    return 2;
  }
  if (case1 === 1 && case2 === 3 && case3 === 1) {
    return 3;
  }
  if (case1 === 2 && case2 === 1 && case3 === 1) {
    return 4;
  }
  if (case1 === 2 && case2 === 2 && case3 === 1) {
    return 5;
  }
  if (case1 === 2 && case2 === 3 && case3 === 1) {
    return 6;
  }
  if (case1 === 3 && case2 === 1 && case3 === 1) {
    return 7;
  }
  if (case1 === 3 && case2 === 2 && case3 === 1) {
    return 8;
  }
  if (case1 === 3 && case2 === 3 && case3 === 1) {
    return 9;
  }
  if (case1 === 1 && case2 === 1 && case3 === 2) {
    return 10;
  }
  if (case1 === 1 && case2 === 2 && case3 === 2) {
    return 11;
  }
  if (case1 === 1 && case2 === 3 && case3 === 2) {
    return 12;
  }
  if (case1 === 2 && case2 === 1 && case3 === 2) {
    return 13;
  }
  if (case1 === 2 && case2 === 2 && case3 === 2) {
    return 14;
  }
  if (case1 === 2 && case2 === 3 && case3 === 2) {
    return 15;
  }
  if (case1 === 3 && case2 === 1 && case3 === 2) {
    return 16;
  }
  if (case1 === 3 && case2 === 2 && case3 === 2) {
    return 17;
  }
  if (case1 === 3 && case2 === 3 && case3 === 2) {
    return 18;
  }
};

export const deleteTags = (str) => {
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const parseYoutubeThumb = (id) => {
  return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
};

export const convertYoutubePeriod = (period) => {
  const reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  let hours = 0,
    minutes = 0,
    seconds = 0;

  if (reptms.test(period)) {
    var matches = reptms.exec(period);
    if (matches[1]) hours = Number(matches[1]);
    if (matches[2]) minutes = Number(matches[2]);
    if (matches[3]) seconds = Number(matches[3]);
  }

  const format = dayjs()?.hour(hours)?.minute(minutes)?.second(seconds);

  return format;
};

export function formatNumber(num) {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
}
export function removeDot(str) {
  return str.replace(/(^0)(?=[^.])/, '');
}

export function replComma(str) {
  return removeDot(str.replace(/(^[\d,]+\.(?:\d*[^0])?)(0*)$/, '$1').replace(/\.$/, ''));
}

export function addComma(str) {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const formatComma = (number, input) => {
  if (!number) {
    return '0';
  }
  const numbers = number.toString().split('.');
  numbers[0] = addComma(numbers[0]);

  if (input) {
    if (typeof numbers[1] === 'string') return removeDot([numbers[0], numbers[1]].join('.'));
    return removeDot(numbers[0]);
  }
  if (typeof number === 'number') {
    return number.toLocaleString();
  }
  if (typeof numbers[1] === 'string') return replComma([numbers[0], numbers[1]].join('.'));
  return replComma(numbers[0]);
};

export default formatComma;
