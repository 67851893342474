export const NAV_MENUS = [
  {
    label: '종목자문',
    path: '/consultation',
    image: {
      on: '/icon/nav_stock_on.png',
      off: '/icon/nav_stock_off.png',
    },
  },
  {
    label: '포트운용',
    path: '/stock',
    image: {
      on: '/icon/nav_port_on.png',
      off: '/icon/nav_port_off.png',
    },
  },
  {
    label: '선물운용',
    path: '/futures',
    image: {
      on: '/icon/nav_futures_on.png',
      off: '/icon/nav_futures_off.png',
    },
  },
  {
    label: '커뮤니티',
    path: '/community',
    image: {
      on: '/icon/nav_community_on.png',
      off: '/icon/nav_community_off.png',
    },
  },
  // {
  //   label: '로그인',
  //   path: '/login',
  //   image: {
  //     on: '/icon/nav_community_on.png',
  //     off: '/icon/nav_community_off.png',
  //   },
  // },
];

export const STOCK_LIST_TABS = [
  {
    type: '국내주식',
    label: '국내주식',
    value: '국내주식',
  },
  {
    type: '미국주식',
    label: '미국주식',
    value: '미국주식',
  },
  {
    type: '가상화폐',
    label: '가상화폐',
    value: '가상화폐',
  },
];

export const PORTFOLIO_LIST_TABS = [
  {
    type: '국내포트',
    label: '국내 Portfolio',
    value: '국내포트',
  },
  {
    type: '해외포트',
    label: '글로벌 Portfolio',
    value: '해외포트',
  },
];

export const RATE_LIST_TABS = [
  {
    type: 'PLUS',
    label: 'BM 대비 (+) 비중',
    value: 'PLUS',
  },
  {
    type: 'MINUS',
    label: 'BM 대비 (-) 비중',
    value: 'MINUS',
  },
];

export const STOCK_LIST_HEADER = [
  {
    label: '종목명',
    style: {
      flex: 2,
    },
  },
  {
    label: '전월대비',
    style: {
      flex: 1,
    },
  },
  {
    label: '추세',
    style: {
      flex: 1,
    },
  },
  {
    label: '강도',
    style: {
      flex: 1.5,
    },
  },
];

export const AI_CONSULT_FILTER1 = [
  {
    key: 'AI_CONSULT_1_1',
    label: '최근 이슈-중립(특별함 없는) 상황',
    value: 1,
  },
  {
    key: 'AI_CONSULT_1_2',
    label: '최근 이슈-긍정적(호재) 상황',
    value: 2,
  },
  {
    key: 'AI_CONSULT_1_3',
    label: '최근 이슈-부정적(악재) 상황',
    value: 3,
  },
];

export const AI_CONSULT_FILTER2 = [
  {
    key: 'AI_CONSULT_2_1',
    label: '최근 거래량-중립(특별함 없는) 상황',
    value: 1,
  },
  {
    key: 'AI_CONSULT_2_2',
    label: '최근 거래량-대량 거래 발생',
    value: 2,
  },
  {
    key: 'AI_CONSULT_2_3',
    label: '최근 거래량-거래량 축소',
    value: 3,
  },
];

export const AI_CONSULT_FILTER3 = [
  {
    key: 'AI_CONSULT_3_1',
    label: '보유 여부-현재 보유',
    value: 1,
  },
  {
    key: 'AI_CONSULT_3_2',
    label: '보유 여부-미 보유',
    value: 2,
  },
];

export const TREND_LABELS = {
  UP: '상승',
  DOWN: '하락',
  NEUT: '중립',
};

export const STOCK_MOBILE_DETAIL_TABS = [
  {
    key: 'diagnosis',
    label: '투자심리판단',
  },
  {
    key: 'consultation',
    label: 'AI 모델 자문',
  },
];

export const PORTFOLIO_MOBILE_DETAIL_TABS = [
  {
    key: 'summary',
    label: 'AI 성과요약',
  },
  {
    key: 'analysis',
    label: '포트 종목분석',
  },
];

export const FUTURES_TYPE_SELETION = [
  {
    key: 'KOSDAQ150',
    label: '코스닥150 선물',
    value: 'KOSDAQ150',
  },
  {
    key: 'S&P500',
    label: 'S&P400 선물',
    value: 'S&P500',
  },
  {
    key: 'NASDAQ100',
    label: '나스닥100 선물',
    value: 'NASDAQ100',
  },
  {
    key: 'BITCOIN',
    label: '비트코인 선물',
    value: 'BITCOIN',
  },
  {
    key: 'ITHERIUM',
    label: '이더리움 선물',
    value: 'ITHERIUM',
  },
];

export const BOARD_CATEGORIES = [
  {
    key: 'all',
    label: '전체',
    value: null,
  },
  {
    key: 'AI/퀀트 채용공고',
    label: 'AI/퀀트 채용공고',
    value: 'AI/퀀트 채용공고',
  },
  {
    key: 'AI/퀀트 행사정보',
    label: 'AI/퀀트 행사정보',
    value: 'AI/퀀트 행사정보',
  },
  {
    key: 'AI/퀀트 게시판',
    label: 'AI/퀀트 게시판',
    value: 'AI/퀀트 게시판',
  },
];

export const BOARD_ORDERS = [
  {
    key: 'createdAt',
    label: '최신순',
    value: 'createdAt',
  },
  {
    key: 'viewCount',
    label: '조회순',
    value: 'viewCount',
  },
];

export const COMMUNITY_TYPE_LABELS = {
  all: '전체',
  recruit: 'AI/퀀트 채용공고',
  event: 'AI/퀀트 행사정보',
  board: 'AI/퀀트 게시판',
};

export const STOCK_CODES = {
  나스닥: 'NASDAQ',
  코스피: 'KRX',
  바이낸스: 'BINANCE',
  코스닥: 'KOSDAQ',
  뉴욕: 'NYSE',
};
