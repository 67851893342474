import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div``;

export const FilterSet = styled.div``;

export const Opinion = styled.div`
  margin-block: 20px;

  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

  & > .sentence {
    display: flex;
    & b {
      color: ${colors.red};
    }
  }
`;

export const ContentSet = styled.div``;

export const Img = styled.img.attrs({ draggable: false, alt: '이미지', width: '100%' })``;
