import React from 'react';
import { useNavigate } from 'react-router';

const HomeIndex = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/consultation');
  }, []);

  return;
};

export default HomeIndex;
