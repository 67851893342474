import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ASK_DUMMY, STOCK_DUMMY } from '../../../common/dummy';
import { Caution, PageContainer, TabNav, TabNavItem } from '../../../components/layout/page';
import PageSection from '../../../components/layout/PageSection';
import TradingViewWidget from '../../../components/chart/TradingViewWidget';
import { HORIZON_START } from '../../../common/align';
import ListTable from '../../../components/list/ListTable';
import { colors } from '../../../common/colors';
import { Col, Row } from 'antd';
import { FormButton, FormSelect, SelectSuffixIcon } from '../../../components/form';
import { AI_CONSULT_FILTER1, AI_CONSULT_FILTER2, AI_CONSULT_FILTER3 } from '../../../common/defines';
import Opinion from '../../../components/text/Opinion';
import MobileDetailHeader from '../../../components/layout/MobileDetailHeader.js';
import dayjs from 'dayjs';
import useScreen from '../../../hooks/useScreen.js';
import { getStockDetail } from '../../../apis/stock/index.js';
import { getCaseNo } from '../../../utils/index.js';

const ConsultationDetailIndex = () => {
  const { id } = useParams();
  const { is_mobile } = useScreen();
  const navigate = useNavigate();
  const {
    state: { record },
  } = useLocation();
  const [tab, setTab] = React.useState('diagnosis');
  const [detail, setDetail] = React.useState();
  const [consultTypes, setConsultTypes] = React.useState({
    case1: 1,
    case2: 1,
    case3: 1,
  });
  const [filterValue, setFilterValue] = React.useState({
    case1: 1,
    case2: 1,
    case3: 1,
  });

  const result_case = React.useMemo(() => {
    return detail?.case1PList?.find(
      (v) => v?.caseNo === getCaseNo(consultTypes?.case1, consultTypes?.case2, consultTypes?.case3),
    );
  }, [detail, consultTypes]);

  const loadData = async () => {
    try {
      await getStockDetail(record?.id).then(({ data }) => {
        setDetail(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  React.useEffect(() => {
    setFilterValue((prev) => ({
      ...prev,
      case1: 1,
      case2: 1,
      case3: 1,
    }));
    setConsultTypes((prev) => ({
      ...prev,
      case1: 1,
      case2: 1,
      case3: 1,
    }));
  }, [detail]);

  React.useEffect(() => {
    if (!is_mobile) {
      navigate('/consultation');
    }
  }, [is_mobile]);

  return (
    <PageContainer style={{ paddingBottom: 120, width: '100%', overflowX: 'scroll' }}>
      <MobileDetailHeader
        titleStyle={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        title={
          <>
            <div>
              <span className={'title'} style={{ marginRight: 4 }}>
                {detail?.name}
              </span>
              <span className={'code'}>{`(${detail?.code})`}</span>
            </div>
            <span className={'date'}>{`${dayjs(detail?.date)?.format('YYYY.MM.DD')} 기준`}</span>
          </>
        }
      />
      <TabNav onChange={setTab} indicatorSize={0}>
        <TabNavItem tab={'투자심리판단'} key={'diagnosis'}>
          <PageSection noHeader={true} wrapperStyle={{ height: 519, marginBottom: 20 }} bodyStyle={{ height: '100%' }}>
            <TradingViewWidget target={detail} />
          </PageSection>

          <PageSection title={'현황해석'} wrapperStyle={{ padding: '20px 16px', marginBottom: 20 }}>
            <div style={{ display: 'flex', color: '#000', fontSize: 14, lineHeight: '20px', letterSpacing: -0.84 }}>
              <span style={{ marginRight: 10 }}>∙</span>
              <span>{detail?.comment1}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>∙</span>
              <span>{detail?.comment2}</span>
            </div>
          </PageSection>

          <PageSection title={'연관 단어'} wrapperStyle={{ padding: '20px 16px', marginBottom: 20 }}>
            <ListTable
              wrapperStyle={{ border: `1px solid ${colors.border}` }}
              dataSource={detail?.textMiningList}
              scrollHeight={227}
              columns={[
                {
                  title: '순위',
                  align: 'center',
                  width: 80,
                  render: (data, record, index) => {
                    return <span>{index + 1}</span>;
                  },
                },
                {
                  title: '연관 키워드(텍스트마이닝)',
                  align: 'center',

                  render: (data, record, index) => {
                    return <span style={{ color: '#a0a0a0' }}>{record?.share}</span>;
                  },
                },
                {
                  title: '점유율',
                  align: 'center',
                  width: 80,
                  render: (data, record, index) => {
                    return <span style={{ color: colors.main }}>{`${record?.rank}%`}</span>;
                  },
                },
              ]}
            />
          </PageSection>
        </TabNavItem>
        <TabNavItem tab={'AI 모델자문'} key={'consultation'}>
          <PageSection noHeader={true} wrapperStyle={{ padding: '20px 16px', marginBottom: 20 }}>
            <Row gutter={[9, 0]}>
              <Col span={20}>
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <FormSelect
                      value={filterValue?.case1}
                      options={AI_CONSULT_FILTER1}
                      style={{ width: '100%' }}
                      suffixIcon={<SelectSuffixIcon />}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          case1: e,
                        }));
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <FormSelect
                      value={filterValue?.case2}
                      options={AI_CONSULT_FILTER2}
                      style={{ width: '100%' }}
                      suffixIcon={<SelectSuffixIcon />}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          case2: e,
                        }));
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <FormSelect
                      value={filterValue?.case3}
                      options={AI_CONSULT_FILTER3}
                      style={{ width: '100%' }}
                      suffixIcon={<SelectSuffixIcon />}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          case3: e,
                        }));
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={4}>
                <FormButton
                  style={{ width: '100%', height: '100%' }}
                  onClick={() => {
                    setConsultTypes(filterValue);
                  }}
                >
                  적용
                </FormButton>
              </Col>
            </Row>
          </PageSection>

          <PageSection noHeader={true} wrapperStyle={{ padding: '32px 16px', marginBottom: 20 }}>
            <Opinion text1={<span>{result_case?.case1}</span>} text2={<span>{result_case?.case2}</span>} />
          </PageSection>

          <PageSection noHeader={true} wrapperStyle={{ padding: '20px 16px', marginBottom: 20 }}>
            <img
              src={`/img/trading_mobile${detail?.picType2}.png`}
              alt={'이미지'}
              draggable={false}
              style={{ width: '100%', aspectRatio: 1 / 0.7, marginBottom: 16 }}
            />
            <img
              src={`/img/trend_mobile${detail?.picType3}.png`}
              alt={'이미지'}
              draggable={false}
              style={{ width: '100%', aspectRatio: 1 / 0.8 }}
            />
          </PageSection>
        </TabNavItem>
      </TabNav>
      <Caution>
        본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한, 제공되는
        모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을 지지 않습니다. 모든
        투자에 대한 책임은 본인에게 있습니다.
      </Caution>
    </PageContainer>
  );
};

export default ConsultationDetailIndex;
