import { restApi } from '..';

const PORT_FOLIO_URL = '/api/portfolio';

export const getPortfolios = async () => {
  try {
    const result = await restApi.get(`${PORT_FOLIO_URL}`);

    return result;
  } catch (error) {
    console.warn('getPortfolios with error', { error });
    throw error;
  }
};

export const getPortfolioDetail = async (id, params) => {
  try {
    const result = await restApi.get(`${PORT_FOLIO_URL}/${id}`, {
      params: {
        ...params,
        year: params?.year === 'all' ? undefined : params?.year,
      },
    });

    return result;
  } catch (error) {
    console.warn('getPortfolioDetail with error', { error });
    throw error;
  }
};
