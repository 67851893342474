import React from 'react';
import { Icon, IconWrapper, Inner, Label, Status, Tab, Wrapper } from './BottomNavigation.style';
import { NAV_MENUS } from '../../../common/defines';
import { shallowEqual, useSelector } from 'react-redux';
import { colors } from '../../../common/colors';

const BottomNavigation = ({ wrapperStyle, location, navigate }) => {
  const { principal } = useSelector((s) => s?.auth, shallowEqual);
  const handleRoute = (path) => {
    navigate(path);
  };

  return (
    <Wrapper style={{ ...wrapperStyle }}>
      {NAV_MENUS?.map((v) => {
        const active = location?.pathname?.includes(v?.path);
        return (
          <Tab
            key={`BOTTOM_NAVIGATION_TAB_${v?.label}`}
            onClick={() => {
              if (active) {
                return;
              }
              handleRoute(v?.path);
            }}
          >
            <IconWrapper>
              <Icon src={v?.image?.[active ? 'on' : 'off']} />
              {v?.path === '/login' ? (
                <Status style={{ backgroundColor: principal ? '#14ae5c' : colors.red_medium }} />
              ) : null}
            </IconWrapper>
            <Label active={active}>{v?.label}</Label>
          </Tab>
        );
      })}
    </Wrapper>
  );
};

export default BottomNavigation;
