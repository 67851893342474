import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div`
  background-color: #fff;
`;

export const Header = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.span`
  color: #42a5ff;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
`;

export const Body = styled.div``;
