import React from 'react';
import useScreen from '../../hooks/useScreen';
import Mobile from './Mobile';
import Default from './Default';

const ConsultationIndex = () => {
  const { is_mobile } = useScreen();

  if (is_mobile) {
    return <Mobile />;
  } else {
    return <Default />;
  }
};

export default ConsultationIndex;
