import { youtubeApi } from '..';

export const getVideoInfo = async (id) => {
  try {
    const result = await youtubeApi.get(
      `/videos?key=AIzaSyDWr_QpK3_034Udy2ftr2lv1k7Qg72gKOo&id=${id}&part=contentDetails,statistics,snippet`,
    );

    return result;
  } catch (error) {
    console.warn({ error });
    throw error;
  }
};

export const getChannelInfo = async (id) => {
  try {
    const result = await youtubeApi.get(`/channels?id=${id}&key=AIzaSyDWr_QpK3_034Udy2ftr2lv1k7Qg72gKOo&part=snippet`);

    return result;
  } catch (error) {
    console.warn({ error });
    throw error;
  }
};
