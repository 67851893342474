import React from 'react';
import { AxiosTable, Wrapper } from './AxiosList.style';
import { getTopBoards } from '../../../apis/community';

const AxiosList = ({ wrapperStyle, params, setParams, restApi, onRow, columns }) => {
  const [dataSource, setDataSource] = React.useState();
  const [data, setData] = React.useState([]);
  const [topData, setTopData] = React.useState([]);

  const loadData = async () => {
    try {
      const { data: tops } = await getTopBoards();

      const addTops = tops?.map((v) => ({ ...v, is_top: true }));
      setTopData(addTops);

      await restApi(params).then(({ data }) => {
        setDataSource(data);

        setData([...addTops, ...data?.content]);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const rowClassName = (record) => {
    if (record?.is_top) {
      return 'is_top';
    }
    return '';
  };

  const pagination = {
    position: ['bottomCenter'],
    total: dataSource?.totalElements + topData?.length,
    pageSize: params?.limit + topData?.length,
    current: params?.page + 1,
    onChange: (page) => {
      setParams((prev) => ({
        ...prev,
        page: page - 1,
      }));
    },
  };

  React.useEffect(() => {
    loadData();
  }, [params]);

  return (
    <Wrapper style={{ ...wrapperStyle }}>
      <AxiosTable
        dataSource={data}
        columns={columns}
        pagination={pagination}
        onRow={onRow}
        rowClassName={rowClassName}
      />
    </Wrapper>
  );
};

export default AxiosList;
