import React from 'react';
import { PageContainer } from '../../components/layout/page';
import PageSection from '../../components/layout/PageSection';
import { STOCK_DUMMY } from '../../common/dummy';
import { STOCK_LIST_TABS, TREND_LABELS } from '../../common/defines';
import { colors } from '../../common/colors';
import TabList from '../../components/list/TabList';
import useScreen from '../../hooks/useScreen';
import { useNavigate } from 'react-router';
import { getStockDetail, getStocks } from '../../apis/stock';

const Mobile = () => {
  const { screenHeight } = useScreen();
  const navigate = useNavigate();
  const [type, setType] = React.useState('국내주식');
  const [data, setData] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');

  const dataSource = React.useMemo(
    () => data?.find((v) => v?.type === type)?.list?.filter((a) => a?.name?.includes(keyword)) || [],
    [data, type, keyword],
  );

  const loadData = async () => {
    try {
      await getStocks()
        .then(({ data: stocks }) => {
          setData(stocks);
        })
        .catch((rej) => {
          console.warn({ rej });
        });
    } catch (error) {
      console.warn({ error });
    }
  };

  const onRow = (record) => {
    return {
      onClick: () => {
        navigate(`/consultation/detail/${record?.id}`, {
          state: {
            record,
          },
        });
      },
    };
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <PageContainer style={{ width: '100%' }}>
      <PageSection noHeader={true}>
        <TabList
          onRow={onRow}
          type={type}
          setType={setType}
          keyword={keyword}
          setKeyword={setKeyword}
          options={STOCK_LIST_TABS}
          dataSource={dataSource}
          scrollHeight={screenHeight - 230}
          columns={[
            {
              title: '종목명',
              align: 'center',
              className: 'left',
              render: (data, record) => {
                return <span style={{ color: '#212121' }}>{record?.name}</span>;
              },
            },
            {
              title: '전월대비',
              align: 'center',
              className: 'ret1m',
              render: (data, record) => {
                return (
                  <span style={{ color: record?.preMonth > 0 ? colors.red : '#212121' }}>{`${
                    record?.preMonth > 0 ? `+${record?.preMonth}%` : `${record?.preMonth}%`
                  }`}</span>
                );
              },
            },
            {
              title: '추세',
              align: 'center',
              width: 60,
              className: 'trend',
              render: (data, record) => {
                return (
                  <span
                    style={{
                      color:
                        record?.trend === '상승' ? colors.red : record?.trend === '하락' ? colors.main : colors.grey,
                      opacity: 0.6,
                    }}
                  >
                    {record?.trend}
                  </span>
                );
              },
            },
            {
              title: '강도',
              align: 'center',
              className: 'str',
              width: 80,
              render: (data, record) => {
                return <span style={{ color: '#a0a0a0' }}>{record?.strength}</span>;
              },
            },
          ]}
        />
      </PageSection>
    </PageContainer>
  );
};

export default Mobile;
