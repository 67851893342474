import React from 'react';

import useScreen from '../../../hooks/useScreen';
import * as C from './TabList.style';
import SearchInput from '../../form/SearchInput';
import ListTable from '../ListTable';

const TabList = ({
  options,
  dataSource = [],
  columns = [],
  scrollHeight,
  onRow,
  wrapperStyle,
  showSearch = true,
  type,
  setType,
  keyword,
  setKeyword,
  tableStyle,
}) => {
  const { is_mobile } = useScreen();

  console.log({ setKeyword });

  return (
    <C.Wrapper style={wrapperStyle}>
      {showSearch ? <SearchInput value={keyword} setKeyword={setKeyword} /> : null}

      <C.Tabs
        options={options}
        value={type}
        onChange={(e) => {
          setType(e);
        }}
      />

      <ListTable
        dataSource={dataSource}
        columns={columns}
        scrollHeight={scrollHeight}
        onRow={onRow}
        wrapperStyle={{ ...tableStyle }}
      />
    </C.Wrapper>
  );
};

export default TabList;
