import React from 'react';
import { PageContainer } from '../../components/layout/page';
import { Col, Row, Segmented, Space, message } from 'antd';
import PageSection from '../../components/layout/PageSection';
import BoardList from '../../components/list/BoardList';
import CustomTabs from '../../components/tabs/CustomTabs';
import { BOARD_CATEGORIES, BOARD_ORDERS } from '../../common/defines';
import { HORIZON_END } from '../../common/align';
import { FormButton, FormSelect, SelectSuffixIcon } from '../../components/form';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import AxiosList from '../../components/list/AxiosList';
import { getBoards, getTopBoards } from '../../apis/community';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearPrincipal, me } from '../../data/auth';
import { restApi, setToken } from '../../apis';
import base64 from 'base-64';
import qs from 'qs';
import SignupModal from '../../components/modal/AuthModal/SignupModal/index';

const Volume = styled.img.attrs({ draggable: false, src: '/icon/volume.png', alt: '아이콘', width: 24, height: 24 })``;

const Default = () => {
  const dispatch = useDispatch();
  const { principal } = useSelector((s) => s?.auth, shallowEqual);
  const [open, setOpen] = React.useState(false);
  const [account, setAccount] = React.useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();

  const [params, setParams] = React.useState({
    category: null,
    page: 0,
    limit: 10,
    sort: 'createdAt',
  });

  const onRow = (record) => {
    return {
      onClick: () => {
        navigate(`/community/detail/${record?.id}`, {
          state: {
            type: record?.category,
          },
        });
      },
    };
  };

  return (
    <>
      {' '}
      <PageContainer>
        <Row gutter={[24, 0]} type={'flex'}>
          <Col span={6}>
            <PageSection
              highlight={true}
              title={<span className={'title'}>인기글 TOP 10</span>}
              bodyStyle={{ padding: '30px 20px' }}
              wrapperStyle={{ height: '100%' }}
            >
              <BoardList />
            </PageSection>
          </Col>
          <Col span={18}>
            <PageSection noHeader={true}>
              <CustomTabs params={params} options={BOARD_CATEGORIES} setParams={setParams} />
              <div style={{ padding: '20px 20px 120px', position: 'relative' }}>
                <div style={{ ...HORIZON_END, width: '100%', marginBottom: 20 }}>
                  <FormSelect
                    value={params?.sort}
                    options={BOARD_ORDERS}
                    onChange={(e) => {
                      setParams((prev) => ({
                        ...prev,
                        sort: e,
                      }));
                    }}
                    suffixIcon={<SelectSuffixIcon />}
                    style={{ width: 149 }}
                  />
                </div>
                <AxiosList
                  params={params}
                  setParams={setParams}
                  onRow={onRow}
                  restApi={async (params) => getBoards(params)}
                  columns={[
                    {
                      title: '번호',
                      align: 'center',
                      width: 80,
                      render: (data, record, index) => {
                        return record?.is_top ? (
                          <Volume />
                        ) : (
                          (data?.totalElements ?? 0) + (params?.limit * params?.page + (index + 1)) - 10
                        );
                      },
                    },
                    {
                      title: '제목',
                      align: 'center',
                      className: 'left',
                      ellipsis: true,
                      render: (data, record, index) => {
                        return <span>{record?.title}</span>;
                      },
                    },
                    {
                      title: '작성자',
                      align: 'center',
                      ellipsis: true,
                      width: 114,
                      render: (data, record, index) => {
                        return <span>{record?.writer}</span>;
                      },
                    },
                    {
                      title: '작성일',
                      align: 'center',
                      width: 152,
                      render: (data, record, index) => {
                        return <span>{dayjs(record?.createdAt)?.format('YYYY-MM-DD')}</span>;
                      },
                    },
                    // {
                    //   title: '추천',
                    //   align: 'center',
                    //   width: 90,
                    //   render: (data, record, index) => {
                    //     return <span>{record?.like}</span>;
                    //   },
                    // },
                    {
                      title: '조회',
                      align: 'center',
                      width: 90,
                      render: (data, record, index) => {
                        return <span>{record?.viewCount}</span>;
                      },
                    },
                  ]}
                />

                <FormButton
                  style={{ width: 99, height: 32, position: 'absolute', right: 20, bottom: 140 }}
                  onClick={() => navigate('/community/register')}
                >
                  글쓰기
                </FormButton>
              </div>
            </PageSection>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

export default Default;
