import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../apis/index';

// export const me = createAsyncThunk('auth/principal', async ({ headers }) => {
//   const { data: principal } = await restApi.get('/api/accounts/authentication', {
//     headers,
//   });

//   console.log({ headers});

//   return principal;
// });

export const me = createAsyncThunk('principal/get', async ({ headers }) => {
  const { data: principal } = await restApi.get(`/api/accounts/authentication`, { headers });
  return { principal };
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    principal: undefined,
  },
  reducers: {
    setPrincipal: (state, action) => {
      state.principal = action.payload.principal;
    },
    clearPrincipal: (state) => {
      state.principal = undefined;
    },
    setTimeout: (state, action) => {
      state.timeout = action.payload;
    },
  },
  extraReducers: {
    [me.fulfilled]: (state, action) => {
      state.principal = action.payload.principal;
      state.permission = action.payload.permission;
    },
  },
});

export const { setPrincipal, clearPrincipal } = authSlice.actions;

export default authSlice.reducer;
