import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div`
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: 20px 0px 20px 0 rgba(42, 42, 42, 0.1);
  display: flex;
  justify-content: space-between;
  padding-inline: 0px;
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 5.5px 10px;
`;

export const IconWrapper = styled.div`
  position: relative;
`;

export const Icon = styled.img.attrs({ draggable: false, alt: '아이콘', width: 24, height: 24 })`
  margin-bottom: 7px;
`;

export const Label = styled.span`
  color: ${({ active }) => {
    return active ? colors.main : '#7c7c7c';
  }};
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;

  & > span > span {
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    font-size: 11px;
  }
`;

export const Status = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: orange;
  position: absolute;
  left: 16px;
  top: 0px;
  left: -4px;
`;
