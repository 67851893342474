import React from 'react';
import useScreen from '../../../hooks/useScreen';
import Mobile from './Mobile';
import Default from './Default';

const PageSection = ({ title, children, wrapperStyle, bodyStyle, noHeader = false, highlight, titleStyle }) => {
  const { is_mobile } = useScreen();

  if (is_mobile) {
    return (
      <Mobile title={title} children={children} wrapperStyle={wrapperStyle} bodyStyle={bodyStyle} noHeader={noHeader} />
    );
  } else {
    return (
      <Default
        title={title}
        children={children}
        wrapperStyle={wrapperStyle}
        bodyStyle={bodyStyle}
        noHeader={noHeader}
        highlight={highlight}
        titleStyle={titleStyle}
      />
    );
  }
};

export default PageSection;
