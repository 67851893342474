import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #a49f9f;
  cursor: pointer;
  box-sizing: border-box;
  margin-inline: 10px;
`;

export const Thumbnail = styled.img.attrs({ draggable: false, alt: '썸네일' })`
  width: 100%;
  aspect-ratio: 1 / 0.7;
`;

export const Body = styled.div`
  padding: 12px 10px 22px;
`;

export const Title = styled.div`
  width: 100%;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 6px;
`;

export const ProfileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ProfileImg = styled.img.attrs({ draggable: false, alt: '프로필 이미지', width: 24, height: 24 })`
  margin-right: 8px;
  border-radius: 50%;
`;

export const Name = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Footer = styled.div`
  background-color: #f1f1f1;
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img.attrs({ draggable: false, alt: '아이콘', width: 16, height: 16 })`
  margin-right: 4px;
`;

export const Hit = styled.span`
  color: #545454;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

export const Date = styled.span`
  color: #a49f9f;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;
