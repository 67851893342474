import { Table } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div``;

export const StyledTable = styled(Table)`
  & * {
    border-radius: 0px !important;
  }

  .ant-table-row.fix {
    background-color: #e4f0ff;
  }

  .ant-table-cell {
    font-size: 14px;
    font-weight: 500 !important;
    padding-inline: 8px !important;
  }

  th.ant-table-cell {
    padding-block: 7.5px !important;
    background-color: ${({ light }) => {
      return light ? 'rgba(66, 165, 255, 0.05) !important' : '#ececec !important';
    }};
  }

  td.ant-table-cell {
    cursor: pointer;
    padding-block: 7.5px !important;
    &.left {
      text-align: left !important;
    }
  }
`;

export const Header = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;

export const Title = styled.span`
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
`;
