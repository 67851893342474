import styled from 'styled-components';
import { colors } from '../../../common/colors';
import ReactApexChart from 'react-apexcharts';
import { Table } from 'antd';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Label = styled.div`
  padding: 6px 10.5px;
  background-color: #e5f3ff;

  color: #2999ff;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
`;

export const Icon = styled.img.attrs({
  draggable: false,
  alt: '아이콘',
  src: '/icon/close.png',
  width: 24,
  height: 24,
})``;

export const Summary = styled.div`
  .sentence {
    display: flex;

    & > span {
      color: #444;
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

export const StyledChart = styled(ReactApexChart)`
  .apexcharts-legend {
    display: none !important;
  }
`;

export const CustomRecord = styled.div``;
