import React from 'react';
import { Item, StyledList } from './BoardList';
import { BOARD_LIST, POPULAR_BOARDS } from '../../../common/dummy';
import { colors } from '../../../common/colors';
import useScreen from '../../../hooks/useScreen';
import { useNavigate } from 'react-router';
import { getTopBoards } from '../../../apis/community';
import { FormButton } from '../../form';
import styled, { css } from 'styled-components';

const Arrow = styled.img.attrs({
  draggable: false,
  alt: '아이콘',
  src: '/icon/arrow_foldable_blue.png',
  width: 8,
  height: 4,
})`
  transform: ${({ open }) => {
    return open ? 'rotate(180deg)' : 'rotate(0deg)';
  }};
`;

const BoardList = () => {
  const navigate = useNavigate();
  const { is_mobile } = useScreen();
  const [dataSource, setDataSource] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const loadData = async () => {
    try {
      await getTopBoards().then(({ data }) => {
        setDataSource(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {' '}
      <StyledList
        is_mobile={is_mobile}
        dataSource={!open && is_mobile ? [...dataSource].slice(0, 3) : [...dataSource]}
        renderItem={(v, i, a) => {
          return (
            <Item
              style={{ marginBottom: a?.length - 1 === i ? 0 : 8 }}
              is_mobile={is_mobile}
              onClick={() => {
                navigate(`/community/detail/${v?.id}`, {
                  state: {
                    type: v?.category,
                  },
                });
              }}
            >
              <span className={'index'} style={{ color: i < 3 ? colors.main : '#afafaf' }}>
                {`${i + 1}.`}&nbsp;
              </span>
              <span className={'title'} style={{ color: i > 2 ? '#afafaf' : '#000' }}>
                {v?.title}
              </span>
            </Item>
          );
        }}
      />
      {is_mobile && dataSource?.length > 3 ? (
        <FormButton
          className={'blur'}
          style={{
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 7,
            width: '100%',
            height: 40,
            marginTop: 8,
          }}
          onClick={() => setOpen((prev) => !prev)}
        >
          <span>더보기</span>
          <Arrow open={open} />
        </FormButton>
      ) : null}
    </>
  );
};

export default BoardList;
