import React from 'react';
import {
  Body,
  Btn,
  Caution,
  Container,
  Footer,
  Header,
  Inner,
  Login,
  Logo,
} from '../../components/style/default/BasicLayout.style';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ROUTE_PAGES } from '../../hooks/useRoutes';
import NotFound from '../../pages/NotFound';

const Default = ({ principal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const headers = React.useMemo(() => ROUTE_PAGES?.filter((v) => v?.is_header), []);

  const handleRoute = React.useCallback((path) => {
    navigate(path);
  }, []);

  return (
    <>
      <Container style={{}}>
        <Header style={{ position: 'fixed', top: 0, width: '100%', zIndex: 999 }}>
          <Inner type={'header'}>
            <Logo onClick={() => navigate('/consultation')} />
            {headers?.map((v) => {
              return (
                <Btn
                  key={`ROUTE_HEADER_BTN_${v?.title}`}
                  onClick={() => handleRoute(v?.path)}
                  active={location?.pathname?.includes(v?.path)}
                >
                  {v?.title}
                </Btn>
              );
            })}
          </Inner>
        </Header>

        <Body style={{ marginTop: 80 }} is_login={location?.pathname === '/login'}>
          <Inner type={'body'}>
            <Routes>
              {ROUTE_PAGES?.map((v) => {
                return <Route key={`ROUTE_CONTENT_${v?.title}`} Component={v?.screen} path={v?.path}></Route>;
              })}
              <Route path={'*'} element={<NotFound />} />
            </Routes>
          </Inner>
        </Body>

        {location?.pathname !== '/login' ? (
          <Footer>
            <Inner type={'footer'}>
              <Caution>
                <span>
                  본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한,
                  제공되는 모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을
                  지지 않습니다. 모든 투자에 대한 책임은 본인에게 있습니다.
                </span>
              </Caution>
            </Inner>
          </Footer>
        ) : null}
      </Container>
    </>
  );
};

export default Default;
