import React from 'react';
import { Caution, PageContainer } from '../../../components/layout/page';
import styled from 'styled-components';
import { Select, message } from 'antd';
import { BOARD_CATEGORIES } from '../../../common/defines';
import { produce } from 'immer';
import PageSection from '../../../components/layout/PageSection';
import {
  FormButton,
  FormCheckbox,
  FormDesc,
  FormDescItem,
  FormInput,
  FormLabel,
  FormPassword,
} from '../../../components/form';
import CustomTinyEditor from '../../../components/editor/CustomTinyEditor';
import { getBoardCategories, getBoardDetail, postBoard, putBoard } from '../../../apis/community';
import { useNavigate } from 'react-router';
import { deleteTags } from '../../../utils';

const BoardTypeSelect = styled(Select)`
  .ant-select {
    border: none !important;
  }

  .ant-select-selector {
    border-radius: 0px;
    border: none !important;
  }

  .ant-select-selection-item {
    font-weight: 700;
  }
`;

const SelectSuffix = styled.img.attrs({
  draggable: false,
  alt: '이이콘',
  width: 8,
  height: 4,
  src: '/icon/arrow_mobile_select.png',
})``;

const Mobile = ({ requestBody, setRequestBody, id }) => {
  const navigate = useNavigate();
  const [category, setCategory] = React.useState([]);
  const [passwordCheck, setPasswordCheck] = React.useState('');

  const loadData = async () => {
    try {
      await getBoardDetail(id).then(({ data }) => {
        setRequestBody(
          produce((draft) => {
            draft.category = data?.category;
            draft.content = data?.content;
            draft.isFix = data?.isFix;
            draft.title = data?.title;
            draft.writer = data?.writer;
          }),
        );
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const onSubmit = async () => {
    try {
      if (!requestBody?.writer) {
        message.error('작성자를 입력하세요.');
        return;
      }
      if (!requestBody?.title) {
        message.error('제목을 입력하세요.');
        return;
      }
      if (!deleteTags(requestBody?.content)) {
        message.error('내용을 입력하세요.');
        return;
      }
      if (!id && requestBody?.password && passwordCheck !== requestBody?.password) {
        message.error('비밀번호가 일치하지 않습니다.');
        return;
      }

      if (id) {
        await putBoard(id, requestBody)
          .then((res) => {
            message.success('수정이 완료되었습니다.');
            navigate('/community');
          })
          .catch((rej) => {
            message.error('수정을 실패했습니다.');
          });
      } else {
        await postBoard(requestBody)
          .then((res) => {
            message.success('등록이 완료되었습니다.');
            navigate('/community');
          })
          .catch((rej) => {
            message.error('등록을 실패했습니다.');
          });
      }
    } catch (error) {
      console.warn({ error });
    }
  };

  const onInputChange = (e) => {
    setRequestBody(
      produce((draft) => {
        draft[e.target.name] = e.target.value;
      }),
    );
  };

  const loadCategories = async () => {
    try {
      await getBoardCategories().then(({ data }) => {
        setCategory(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const form_data = [
    {
      label: <FormLabel>상단 고정 여부</FormLabel>,
      content: (
        <FormCheckbox
          checked={requestBody?.isFix}
          onChange={(e) => {
            setRequestBody((prev) => ({
              ...prev,
              isFix: e.target.checked,
            }));
          }}
        />
      ),
    },
    {
      label: <FormLabel required>작성자</FormLabel>,
      content: (
        <FormInput
          placeholder={'작성자를 입력하세요.'}
          value={requestBody?.writer}
          name={'writer'}
          onChange={onInputChange}
        />
      ),
    },
    {
      label: <FormLabel required>제목</FormLabel>,
      content: (
        <FormInput
          placeholder={'제목을 입력하세요.'}
          value={requestBody?.title}
          name={'title'}
          onChange={onInputChange}
        />
      ),
    },
    {
      label: <FormLabel required>내용</FormLabel>,
      content: (
        <div style={{ width: '100%' }}>
          <CustomTinyEditor
            height={300}
            value={requestBody?.content}
            onChange={(e) => {
              setRequestBody(
                produce((draft) => {
                  draft.content = e;
                }),
              );
            }}
          />
        </div>
      ),
    },
    {
      label: <FormLabel>잠금 비밀번호</FormLabel>,
      content: (
        <FormPassword
          placeholder={'잠금 비밀번호'}
          value={requestBody?.password}
          name={'password'}
          onChange={onInputChange}
        />
      ),
    },
    {
      label: <FormLabel required={requestBody?.password}>비밀번호 확인</FormLabel>,
      content: (
        <FormPassword
          placeholder={'비밀번호 확인'}
          value={passwordCheck}
          onChange={(e) => setPasswordCheck(e.target.value)}
        />
      ),
    },
  ];

  React.useEffect(() => {
    loadCategories();
  }, []);

  React.useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  return (
    <PageContainer style={{ paddingBottom: 150 }}>
      <div style={{ padding: '19px 15px 16px' }}>
        <BoardTypeSelect
          value={requestBody?.category}
          style={{ width: '100%', height: 43 }}
          suffixIcon={<SelectSuffix />}
          options={category?.map((v) => ({
            key: v,
            label: v,
            value: v,
          }))}
          onChange={(e) => {
            setRequestBody(
              produce((draft) => {
                draft.category = e;
              }),
            );
          }}
        />
      </div>

      <PageSection noHeader={true} bodyStyle={{ padding: '18px 16px 20px' }}>
        <FormDesc labelStyle={{ width: 200 }} colon={false} layout="vertical">
          {(id ? form_data?.slice(0, form_data?.length - 1) : form_data)?.map((v, i) => {
            return (
              <FormDescItem key={`BOARD_FORM_${i}`} label={v?.label} span={4}>
                {v?.content}
              </FormDescItem>
            );
          })}
        </FormDesc>

        <FormButton style={{ marginTop: 20, width: '100%', height: 40 }} onClick={onSubmit}>
          저장
        </FormButton>
      </PageSection>
      <Caution style={{ paddingTop: 11 }}>
        본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한, 제공되는
        모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을 지지 않습니다. 모든
        투자에 대한 책임은 본인에게 있습니다.
      </Caution>
    </PageContainer>
  );
};

export default Mobile;
