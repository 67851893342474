import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const StyledTable = styled.table.attrs({})`
  width: 100%;

  ${({ is_mobile }) => {
    return is_mobile
      ? css``
      : css`
          border: 1px solid #c2c2c2;
        `;
  }}
`;

export const Tr = styled.tr`
  ${({ is_mobile }) => {
    return is_mobile ? css`` : css``;
  }}
`;

export const Th = styled.th`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          border: 1px solid #c2c2c2;
          padding-block: 5px;
          padding-inline: 0px;
          background-color: #ececec;

          &.ai_th {
            background-color: #ffeded;
          }
          &.year {
            color: #424242;
            font-size: 10.563px;
            font-weight: 500;
            line-height: normal;
          }
        `
      : css`
          background-color: #838383;
          padding-block: 5px;

          color: #fff;
          font-size: 10px;
          font-weight: 500;
          line-height: normal;
        `;
  }}
`;

export const Td = styled.td`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          text-align: center;
          color: #212121;
          font-size: 10.563px;
          font-weight: 500;
          line-height: normal;

          padding-block: 5px;
          padding-inline: 0px;

          border-right: 1px solid #c2c2c2;
          border-bottom: 1px solid #c2c2c2;
        `
      : css`
          padding-block: 5px;
          text-align: center;

          color: #212121;
          font-size: 10px;
          font-weight: 500;
          line-height: normal;

          &.ai_td {
            background-color: #ffeded;
            width: 81px;
          }
          &.bm_td {
            background-color: #f6f6f6;
          }
        `;
  }}
`;

export const Label = styled.div`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 7.5px;
          & > .index {
            width: 12px;
            height: 12px;
          }

          &.ai {
            & > .index {
              background-color: #f24f4f;
            }
          }

          &.bm {
            & > .index {
              background-color: #a3a3a3;
            }
          }
        `
      : css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 7.5px;

          & > .index {
            width: 9px;
            height: 9px;
          }

          &.ai {
            & > .index {
              background-color: #f24f4f;
            }
          }

          &.bm {
            & > .index {
              background-color: #cacaca;
            }
          }
        `;
  }}
`;
