import React from 'react';
import { useLocation, useParams } from 'react-router';
import { BOARD_LIST } from '../../../common/dummy';
import useScreen from '../../../hooks/useScreen';
import Mobile from './Mobile';
import Default from './Default';
import { getBoardDetail } from '../../../apis/community';
import { shallowEqual, useSelector } from 'react-redux';

const CommunityDetailIndex = () => {
  const { is_mobile } = useScreen();
  const { id } = useParams();
  const { state } = useLocation();
  const [detail, setDetail] = React.useState();
  const { principal } = useSelector((s) => s?.auth, shallowEqual);

  const loadData = async () => {
    try {
      await getBoardDetail(id)
        .then(({ data }) => {
          setDetail(data);
        })
        .catch((rej) => {});
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  if (is_mobile) {
    return <Mobile data={detail} state={state} principal={principal} />;
  } else {
    return <Default data={detail} state={state} principal={principal} />;
  }
};

export default CommunityDetailIndex;
