import React from 'react';
import { BOARD_INIT } from '../../../common/init';
import useScreen from '../../../hooks/useScreen';
import Mobile from './Mobile';
import Default from './Default';
import { useLocation } from 'react-router';

const CommunityRegisterIndex = () => {
  const { is_mobile } = useScreen();
  const [requestBody, setRequestBody] = React.useState(BOARD_INIT);
  const { state } = useLocation();

  if (is_mobile) {
    return <Mobile requestBody={requestBody} setRequestBody={setRequestBody} id={state?.id} />;
  } else {
    return <Default requestBody={requestBody} setRequestBody={setRequestBody} id={state?.id} />;
  }
};

export default CommunityRegisterIndex;
