import HomeIndex from '../pages';
import NotFound from '../pages/NotFound';
import CommunityIndex from '../pages/community';
import CommunityDetailIndex from '../pages/community/detail';
import CommunityRegisterIndex from '../pages/community/register';
import ConsultationIndex from '../pages/consultation';
import ConsultationDetailIndex from '../pages/consultation/detail';

import FuturesIndex from '../pages/futures';
import LoginIndex from '../pages/login';
import StockIndex from '../pages/stock';
import StockDetailIndex from '../pages/stock/detail';

export const ROUTE_PAGES = [
  {
    path: '/',
    title: '홈',
    screen: HomeIndex,
    is_header: false,
  },
  {
    path: '/login',
    title: '로그인',
    screen: LoginIndex,
    is_header: false,
  },
  {
    path: '/consultation',
    title: '종목자문 AI',
    screen: ConsultationIndex,
    is_header: true,
    is_main: true,
  },
  {
    path: '/consultation/detail/:id',
    title: '종목자문 AI 상세',
    screen: ConsultationDetailIndex,
  },
  {
    path: '/stock',
    title: '주식운용 AI',
    screen: StockIndex,
    is_header: true,
    is_main: true,
  },
  {
    path: '/stock/detail',
    title: '주식운용 AI 상세',
    screen: StockDetailIndex,
  },
  {
    path: '/futures',
    title: '선물운용 AI',
    screen: FuturesIndex,
    is_header: true,
    is_main: true,
  },
  {
    path: '/community',
    title: '퀀트 커뮤니티',
    screen: CommunityIndex,
    is_header: true,
    is_main: true,
  },
  {
    path: '/community/detail/:id',
    title: '퀀트 커뮤니티 상세',
    screen: CommunityDetailIndex,
  },
  {
    path: '/community/register',
    title: '퀀트 커뮤니티 등록',
    screen: CommunityRegisterIndex,
  },
];
