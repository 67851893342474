import React from 'react';
import { restApi } from '../apis';
import xlsx from 'xlsx';
import { message } from 'antd';

const useExcel = (props) => {
  const filename = props.filename;
  const parsing = props.parsing;
  const [state, setState] = React.useState({
    loading: false,
    error: false,
  });

  function autofitColumns(json, worksheet, header) {
    const jsonKeys = header || Object.keys(json[0]);

    const objectMaxLength = [];
    for (let i = 0; i < json.length; i++) {
      const value = json[i];
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10;
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
        }
      }

      const key = jsonKeys;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length;
      }
    }
  }

  const download = async () => {
    try {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));

      const { data } = await restApi.get(props.path, {
        params: {
          ...props.params,
        },
      });

      if (data?.portfolioHistoryList?.length > 0) {
        const xlsxData = [];
        const xlsxData2 = [];

        const plus = data?.portfolioHistoryList?.filter((v) => v?.type === 'PLUS');
        const minus = data?.portfolioHistoryList?.filter((v) => v?.type === 'MINUS');

        plus?.forEach((element, index) => {
          if (parsing) {
            const item = parsing(element, index);
            xlsxData.push({ no: index + 1, ...item });
          }
        });

        if (xlsxData?.length > 0) {
          const ws = xlsx.utils.json_to_sheet(xlsxData);
          autofitColumns(xlsxData, ws);
          const wb = xlsx.utils.book_new();

          xlsx.SSF.get_table();

          xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
          xlsx.writeFile(wb, String(`BM 대비 (+) 비중.xlsx`).trim());
        }

        minus?.forEach((element, index) => {
          if (parsing) {
            const item = parsing(element, index);
            xlsxData2.push({ no: index + 1, ...item });
          }
        });

        if (xlsxData2?.length > 0) {
          const ws = xlsx.utils.json_to_sheet(xlsxData2);
          autofitColumns(xlsxData2, ws);
          const wb = xlsx.utils.book_new();

          xlsx.SSF.get_table();

          xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
          xlsx.writeFile(wb, String(`BM 대비 (-) 비중.xlsx`).trim());
        }
      } else {
        message.error('조회된 데이터가 없습니다.');
      }

      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      console.warn({ error });
      setState((prev) => ({
        ...prev,
        loading: false,
        error: true,
      }));
    }
  };

  return { ...state, download };
};

export default useExcel;
