import React from 'react';
import * as C from './Opinion.style';

const Opinion = ({ text1, text2 }) => {
  return (
    <C.Wrapper>
      <C.Sentence>
        <span>∙</span>
        {text1}
      </C.Sentence>
      <C.Sentence>
        <span>∙</span>
        {text2}
      </C.Sentence>
    </C.Wrapper>
  );
};

export default Opinion;
