import React from 'react';
import * as C from './ChannelItem.style';
import { getChannelInfo, getVideoInfo } from '../../../apis/youtube';
import { produce } from 'immer';
import formatComma, { convertYoutubePeriod } from '../../../utils';
import dayjs from 'dayjs';

const ChannelItem = ({ item, index }) => {
  const [info, setInfo] = React.useState({
    title: '',
    viewCount: 0,
    thumbnail: '',
    channelTitle: '',

    duration: '',
  });

  const loadVideoInfo = async () => {
    try {
      const { data: video } = await getVideoInfo(item);

      setInfo(
        produce((draft) => {
          draft.viewCount = video?.items?.[0]?.statistics?.viewCount;
          draft.title = video?.items?.[0]?.snippet?.localized?.title;
          draft.thumbnail = video?.items?.[0]?.snippet?.thumbnails?.standard?.url;
          draft.channelTitle = video?.items?.[0]?.snippet?.channelTitle;
          draft.duration = video?.items?.[0]?.contentDetails?.duration;
        }),
      );

      const { data: channel } = await getChannelInfo(video?.items?.[0]?.snippet?.channelId);

      setInfo(
        produce((draft) => {
          draft.profile = channel?.items?.[0]?.snippet?.thumbnails?.medium?.url;
        }),
      );
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    if (item) {
      loadVideoInfo();
    }
  }, [item]);

  return (
    <a href={`https://www.youtube.com/watch?v=${item}`} target={'_blank'} draggable={false}>
      <C.Wrapper>
        <C.Thumbnail src={info?.thumbnail} />
        <C.Body>
          <C.Title>{info?.title}</C.Title>
          <C.ProfileWrapper>
            <C.ProfileImg src={info?.profile} />
            <C.Name>{info?.channelTitle}</C.Name>
          </C.ProfileWrapper>
        </C.Body>

        <C.Footer>
          <C.SubInfo>
            <C.Icon src={'/icon/eye.png'} />
            <C.Hit>{formatComma(info?.viewCount)}</C.Hit>
          </C.SubInfo>
          <C.SubInfo>
            <C.Icon src={'/icon/clock.png'} />
            <C.Date>{dayjs(convertYoutubePeriod(info?.duration))?.format('HH:mm:ss')}</C.Date>
          </C.SubInfo>
        </C.Footer>
      </C.Wrapper>
    </a>
  );
};

export default ChannelItem;
