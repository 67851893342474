import React from 'react';
import * as C from './TimeLine.style';
import { Timeline, message } from 'antd';
import { TIMELINE_LIST } from '../../../common/dummy';
import dayjs from 'dayjs';
import { getFutures, getNews, getYears } from '../../../apis/futures';

const TimeLine = ({ setHover, chart, year, setYear, allTimeChart }) => {
  const [data, setData] = React.useState([]);
  const [record, setRecord] = React.useState([]);
  // const [params, setParams] = React.useState({
  //   date: '2023-10-19',
  // });

  const loadData = async () => {
    try {
      await getNews().then(({ data: news }) => {
        setData(news);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  console.log({ chart });

  return (
    <C.Wrapper>
      <C.Body>
        {' '}
        <C.StyledTimeLine>
          {data?.map((v, i) => {
            // const active = dayjs(params?.date)?.format('YYYY-MM-DD') === dayjs(v?.date)?.format('YYYY-MM-DD');
            const active = record?.id === v?.id;
            return (
              <C.StyledTimeLineItem color={'grey'} key={`NEWS_TIMELINE_${i}`} active={active}>
                <C.Item
                  onClick={() => {
                    const dates = allTimeChart?.find((a) => a?.date === v?.date);

                    setRecord(v);
                    if (!dates) {
                      message.error(`날짜(${dayjs(v?.date)?.format('YYYY-MM-DD')})와 일치하는 데이터가 없습니다.`);

                      return;
                    }

                    if (year?.value !== dayjs(v?.date)?.year() && year?.value !== 'all') {
                      console.log('확인');
                      setYear({ type: 'all', value: 'all' });
                    }

                    setHover(v?.date);

                    // if (!dates) {
                    //   message.error('날짜와 일치하는 데이터가 없습니다.');
                    // }
                  }}
                >
                  <C.Tag active={active}>{dayjs(v?.date)?.format('YYYY-MM-DD')}</C.Tag>
                  <C.Content active={active}>{v?.newsTitle}</C.Content>
                </C.Item>
              </C.StyledTimeLineItem>
            );
          })}
        </C.StyledTimeLine>
      </C.Body>
    </C.Wrapper>
  );
};

export default TimeLine;
