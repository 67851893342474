import { Tabs } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const PageContainer = styled.div``;

export const TabNav = styled(Tabs)`
.ant-tabs-nav {
        margin: 0px !important;
    }

  & .ant-tabs-nav-list {
    width: 100% !important;
  }

  .ant-tabs-tab {
    flex: 1;
    margin: 0px !important;
    padding-block: 14.5px;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    border-bottom: 1px solid #c2c2c2;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${colors.main};
    border-bottom: 1px solid ${colors.main};

    .ant-tabs-tab-btn {
      color: #fff;

      font-weight: 700;
    }
  }
  .ant-tabs-tab-btn {
    color: #9e9e9e;
    font-size: 16px;
    line-height: normal;
    font-weigth; 400;
  }
`;

export const TabNavItem = styled(Tabs.TabPane)``;

export const Caution = styled.div`
  color: #898f98;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-inline: 16px;
`;
