import React from 'react';

import PageSection from '../../components/layout/PageSection';
import { PageContainer } from '../../components/layout/page';
import { Col, Row } from 'antd';
import TabList from '../../components/list/TabList';
import { STOCK_LIST_TABS, TREND_LABELS } from '../../common/defines';
import { STOCK_DUMMY, TEXT_MINING_LIST } from '../../common/dummy';
import TradingViewWidget from '../../components/chart/TradingViewWidget';
import ConsultResultSet from '../../components/layout/ConsultResultSet';
import { colors } from '../../common/colors';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { FormButton } from '../../components/form';
import { getStockDetail, getStocks } from '../../apis/stock';
import { HORIZON_START } from '../../common/align';
import dayjs from 'dayjs';
import ModelView from '../../components/chart/ModelView';
import useScreen from '../../hooks/useScreen';

const Default = () => {
  const { is_mobile, screenWidth } = useScreen();
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState('국내주식');
  const [keyword, setKeyword] = React.useState('');
  const [isModel, setIsModel] = React.useState(false);
  const [target, setTarget] = React.useState();
  const [detail, setDetail] = React.useState();
  const [consultTypes, setConsultTypes] = React.useState({
    case1: 1,
    case2: 1,
    case3: 1,
  });
  const dataSource = React.useMemo(() => {
    return (
      data
        ?.find((v) => v?.type === type)
        ?.list?.filter((a) => {
          console.log({ a }, 'aaaaa');
          console.log({ keyword });
          return a?.name?.includes(keyword);
        }) || []
    );
  }, [data, type, keyword]);

  console.log({ dataSource, keyword });

  const loadData = async () => {
    try {
      await getStocks()
        .then(({ data: stocks }) => {
          setData(stocks);

          setTarget(stocks?.[0]?.list?.[0]);
        })
        .catch((rej) => {
          console.warn({ rej });
        });
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadDetailData = async () => {
    try {
      await getStockDetail(target?.id)
        .then(({ data }) => {
          setDetail(data);
        })
        .catch((rej) => {
          console.warn({ rej });
        });
    } catch (error) {
      console.warn({ error });
    }
  };

  const onRow = (record) => {
    return {
      onClick: () => {
        setTarget(record);
      },
    };
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (target?.id) {
      loadDetailData();
    }
  }, [target]);

  return (
    <PageContainer>
      <Row gutter={[24, 20]} type="flex">
        <Col span={screenWidth < 1400 ? 24 : 16}>
          <PageSection
            titleStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            wrapperStyle={{ height: '100%' }}
            bodyStyle={{ height: screenWidth < 1400 ? 500 : 'calc(100% - 49px)' }}
            title={
              <>
                <div style={{ ...HORIZON_START }}>
                  <span className={'title'} style={{ marginRight: 7 }}>
                    {target?.name}
                  </span>
                  <span className={'code'} style={{ marginRight: 6 }}>
                    {target?.code}
                  </span>
                  <div style={{ marginRight: 9 }} className={'type'}>
                    {target?.type === '국내주식' ? '국내' : target?.type === '미국주식' ? '미국' : '비트코인'}
                  </div>
                  <FormButton onClick={() => setIsModel((prev) => !prev)}>
                    {isModel ? '차트보기' : '투자심리 판단 →'}
                  </FormButton>
                </div>
                <span className={'date'}>{`${dayjs(target?.date)?.format('YYYY-MM-DD')} 기준(장마감)`}</span>
              </>
            }
          >
            {isModel ? (
              <ModelView wrapperStyle={{ paddingTop: 30, paddingInline: 20 }} target={detail} />
            ) : (
              <TradingViewWidget target={detail} />
            )}
          </PageSection>
        </Col>
        <Col span={screenWidth < 1400 ? 24 : 8}>
          <PageSection noHeader={true}>
            <TabList
              wrapperStyle={{ height: 542 }}
              keyword={keyword}
              setKeyword={setKeyword}
              onRow={onRow}
              type={type}
              setType={setType}
              options={STOCK_LIST_TABS}
              dataSource={dataSource}
              scrollHeight={416}
              columns={[
                {
                  title: '종목명',
                  align: 'center',
                  className: 'left',
                  render: (data, record) => {
                    return <span style={{ color: '#212121', whiteSpace: 'nowrap' }}>{record?.name}</span>;
                  },
                },
                {
                  title: '전월대비',
                  align: 'center',
                  className: 'ret1m',
                  width: 104,
                  render: (data, record) => {
                    return (
                      <span style={{ color: record?.preMonth > 0 ? colors.red : '#212121' }}>{`${
                        record?.preMonth > 0 ? `+${record?.preMonth}%` : `${record?.preMonth}%`
                      }`}</span>
                    );
                  },
                },
                {
                  title: '추세',
                  align: 'center',
                  className: 'trend',
                  width: 70,
                  render: (data, record) => {
                    return (
                      <span
                        style={{
                          color:
                            record?.trend === '상승'
                              ? colors.red
                              : record?.trend === '하락'
                              ? colors.main
                              : colors.grey,
                          opacity: 0.6,
                        }}
                      >
                        {record?.trend}
                      </span>
                    );
                  },
                },
                {
                  title: '강도',
                  align: 'center',
                  className: 'str',
                  width: 80,
                  render: (data, record) => {
                    return <span style={{ color: '#a0a0a0' }}>{record?.strength}</span>;
                  },
                },
              ]}
            />
          </PageSection>
        </Col>
        <Col span={24}>
          <PageSection
            title={<span className={'title'}>AI 모델 자문 결과</span>}
            bodyStyle={{ padding: '20px 20px 24px' }}
          >
            <ConsultResultSet
              target={detail}
              consultTypes={consultTypes}
              setConsultTypes={setConsultTypes}
              list={detail?.textMiningList}
            />
          </PageSection>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Default;
