import { Timeline } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div`
  height: 490px;
`;

export const Body = styled.div`
  padding: 34px 20px 70px;
  overflow: scroll;
  height: 100%;
`;

export const StyledTimeLine = styled(Timeline)`
  .ant-timeline-item-tail {
    border-style: dashed !important;
    border-color: #808080;
    border-width: 1px;
  }

  .ant-timeline-item {
    padding-bottom: 10px !important;
  }
  .ant-timeline-item-head {
    background-color: #808080;
    border-color: #808080 !important;
  }
`;

export const StyledTimeLineItem = styled(Timeline.Item)``;

export const Item = styled.div`
  cursor: pointer;
`;

export const Tag = styled.div`
  width: 77px;
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  transition: 0.3s all;
  ${({ active }) => {
    return active
      ? css`
          color: #fff;
          background-color: ${colors.red_medium};
        `
      : css`
          color: #808080;
          background-color: #f1f1f1;
        `;
  }};
`;

export const Content = styled.span`
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 0.3s all;

  color: ${({ active }) => {
    return active ? colors.red_medium : '#939393';
  }};
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;
