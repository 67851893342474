import axios from 'axios';

export const TOKEN_STORE_KEY = 'maimai-token-data';

export const getAPIHost = () => {
  return 'https://api.maimai.kr'; // 운영
  return 'https://maimai-serv.actbase.dev'; // 개발
};

export const baseURL = `${getAPIHost()}`;

export const restApi = axios.create({
  baseURL,
});

export const authApi = axios.create({
  baseURL,
});

export const youtubeApi = axios.create({ baseURL: 'https://www.googleapis.com/youtube/v3/' });

restApi.interceptors.request.use((config) => {
  const access_token = localStorage?.getItem(TOKEN_STORE_KEY);

  if (access_token) config.headers.Authorization = `bearer ${access_token}`;
  return config;
});

export const setToken = (cookies, setCookie) => {
  if (!cookies?.access_token) {
    localStorage.setItem(TOKEN_STORE_KEY, '');
    return '';
  }

  const { access_token, refresh_token, expires_in } = cookies;

  if (access_token) {
    localStorage.setItem(TOKEN_STORE_KEY, access_token);
  }
};

export const getToken = () => {
  const access_token = localStorage.getItem(TOKEN_STORE_KEY);

  return { access_token };
};
