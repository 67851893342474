import React from 'react';
import { PageContainer } from '../../components/layout/page';
import { Col, Row } from 'antd';
import PageSection from '../../components/layout/PageSection';
import { PORTFOLIO_LIST_TABS, TREND_LABELS } from '../../common/defines';
import { PORTFOLIO_DUMMY, STOCK_DUMMY } from '../../common/dummy';
import TabList from '../../components/list/TabList';
import { colors } from '../../common/colors';
import ListTable from '../../components/list/ListTable';
import { HORIZON_BETWEEN, HORIZON_CENTER } from '../../common/align';
import { FormButton, FormDatePicker } from '../../components/form';
import { BM_LIST } from '../../common/dummy2';
import EarningRateChart from '../../components/chart/EarningRateChart.js';
import PeriodButtonSet from '../../components/filter/PeriodButtonSet/index.js';
import SummaryChart from '../../components/chart/SummaryChart.js/index.js';
import { getPortfolioDetail, getPortfolios } from '../../apis/portfolio/index.js';
import dayjs from 'dayjs';
import { getSign } from '../../apis/futures/index.js';
import EarningRateChart2 from '../../components/chart/EarningRateChart2/index.js';
import useScreen from '../../hooks/useScreen.js';
import useExcel from '../../hooks/useExcel.js';

const Default = () => {
  const { screenWidth } = useScreen();
  const [portfolios, setPortfolios] = React.useState([]);
  const [type, setType] = React.useState('국내포트');
  const [target, setTarget] = React.useState();
  const [detail, setDetail] = React.useState();
  const [keyword, setKeyword] = React.useState('');
  const [isSummary, setIsSummary] = React.useState(false);
  const [year, setYear] = React.useState({ type: 'all', value: 'all' });
  const [date, setDate] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [dateFilter, setDateFilter] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [histories, setHistories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const parsing = (v, index) => ({
    포트폴리오명: v?.name,
    추세: v?.trend,
    강도: v?.strength,
  });

  const excel = useExcel({
    path: `/api/portfolio/${target?.id}`,
    filename: 'BM 대비 (+) 비중',
    parsing,
    params: {
      date,
    },
  });

  const list = React.useMemo(() => {
    return (
      portfolios
        ?.find((v) => v?.type === type)
        ?.list?.filter((a) => {
          console.log({ a }, 'aaaaa');
          return a?.name?.includes(keyword);
        }) || []
    );
  }, [portfolios, type, keyword]);

  const historyList = React.useMemo(() => {
    const plus = histories?.filter((v) => v?.type === 'PLUS');

    const minus = histories?.filter((v) => v?.type === 'MINUS');
    return { plus, minus };
  }, [histories]);

  const onRow = (record) => {
    return {
      onClick: () => {
        setTarget(record);
      },
    };
  };

  const handleExcel = () => {
    try {
      excel.download();
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadPortfolios = async () => {
    try {
      await getPortfolios()
        .then(({ data }) => {
          setPortfolios(data);

          setTarget(data?.[0]?.list?.[0]);
        })
        .catch((rej) => {
          console.warn({ rej });
        });
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadDetail = async () => {
    try {
      setLoading(true);
      await getPortfolioDetail(target?.id, { year: year?.value }).then(({ data }) => {
        setDetail(data);
      });
    } catch (error) {
      console.warn({ error });
    } finally {
      setLoading(false);
    }
  };

  const loadHistories = async () => {
    try {
      await getPortfolioDetail(target?.id, { date }).then(({ data }) => {
        setHistories(data?.portfolioHistoryList);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    loadPortfolios();
  }, []);

  React.useEffect(() => {
    if (target?.id) {
      loadDetail();
    }
  }, [target, year]);

  React.useEffect(() => {
    if (target?.id) {
      loadHistories();
    }
  }, [target, date]);

  console.log({ detail });

  return (
    <PageContainer>
      <Row gutter={[24, 20]} type="flex">
        <Col span={screenWidth < 1400 ? 24 : 16}>
          <PageSection
            titleStyle={{ display: 'flex', alignItems: 'center' }}
            title={
              <>
                <span className={'title'}>{detail?.name}</span>
                <div className={'type'} style={{ marginLeft: 17, marginRight: 12 }}>
                  {detail?.type === '국내포트' ? '국내' : '해외'}
                </div>
                <FormButton onClick={() => setIsSummary((prev) => !prev)}>
                  {isSummary ? '요약 닫기' : '성과 요약 →'}
                </FormButton>
              </>
            }
            wrapperStyle={{ height: '100%' }}
            bodyStyle={{ height: screenWidth < 1400 ? 500 : '100%' }}
          >
            {isSummary ? (
              <SummaryChart
                dataSource={detail?.performanceSummaryList}
                comment1={detail?.comment1}
                comment2={detail?.comment2}
                wrapperStyle={{ padding: '20px 20px 16px', height: 'calc(100% - 280px)' }}
                onClose={() => setIsSummary(false)}
              />
            ) : (
              <>
                <PeriodButtonSet
                  wrapperStyle={{
                    padding: '30px 13px 20px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 4,
                  }}
                  data={year?.type}
                  setData={(v) => {
                    setYear(v);
                  }}
                />
                <EarningRateChart data={detail?.dailyPdxs} year={year} loading={loading} />
              </>
            )}
          </PageSection>
        </Col>
        <Col span={screenWidth < 1400 ? 24 : 8}>
          <PageSection noHeader={true}>
            <TabList
              onRow={onRow}
              type={type}
              setType={setType}
              keyword={keyword}
              setKeyword={setKeyword}
              options={PORTFOLIO_LIST_TABS}
              dataSource={list}
              scrollHeight={416}
              columns={[
                {
                  title: '포트폴리오명',
                  align: 'center',
                  className: 'left',
                  render: (data, record) => {
                    return <span style={{ color: '#212121' }}>{record?.name}</span>;
                  },
                },
                {
                  title: '1M 수익률',
                  align: 'center',
                  width: 100,
                  render: (data, record) => {
                    return (
                      <span style={{ color: record?.preMonth > 0 ? colors.red : colors?.main }}>{`${
                        record?.preMonth > 0 ? `+${record?.preMonth}%` : `${record?.preMonth}%`
                      }`}</span>
                    );
                  },
                },
                {
                  title: '초과성과',
                  align: 'center',
                  width: 100,
                  render: (data, record) => {
                    return (
                      <span style={{ color: record?.over > 0 ? colors.red : colors?.main }}>{`${
                        record?.over > 0 ? `+${record?.over}%` : `${record?.over}%`
                      }`}</span>
                    );
                  },
                },
              ]}
            />
          </PageSection>
        </Col>

        <Col span={24}>
          <PageSection
            titleStyle={{ ...HORIZON_BETWEEN }}
            title={
              <>
                <span className={'title'}>포트폴리오 종목 AI 분석</span>
                <div style={{ ...HORIZON_CENTER }}>
                  <span className={'label'}>조회일</span>
                  <FormDatePicker
                    style={{ width: 143, marginInline: 7 }}
                    allowClear={false}
                    value={dayjs(dateFilter, 'YYYY-MM-DD')}
                    onChange={(e) => {
                      setDateFilter(dayjs(e)?.format('YYYY-MM-DD'));
                    }}
                  />
                  <FormButton
                    style={{ marginRight: 7 }}
                    onClick={() => {
                      setDate(dateFilter);
                    }}
                  >
                    검색
                  </FormButton>
                  <FormButton className={'blur'} onClick={handleExcel}>
                    엑셀 다운로드
                  </FormButton>
                </div>
              </>
            }
            bodyStyle={{ padding: '50px 50px 33px' }}
          >
            <Row gutter={[20, 40]}>
              <Col span={screenWidth < 1400 ? 24 : 12}>
                <ListTable
                  title={'BM 대비 (+) 비중'}
                  scrollHeight={427}
                  tableStyle={{ border: '1px solid #eff2fa' }}
                  dataSource={historyList?.plus}
                  columns={[
                    {
                      title: '종목',
                      align: 'center',
                      className: 'left',
                      render: (data, record) => {
                        return <span style={{ color: '#212121' }}>{record?.stock}</span>;
                      },
                    },
                    {
                      title: '추세',
                      align: 'center',
                      className: 'trend',
                      render: (data, record) => {
                        return (
                          <span
                            style={{
                              color:
                                record?.trend === '상승'
                                  ? colors.red
                                  : record?.trend === '하락'
                                  ? colors.main
                                  : colors.grey,
                              opacity: 0.6,
                            }}
                          >
                            {record?.trend}
                          </span>
                        );
                      },
                    },
                    {
                      title: '강도',
                      align: 'center',
                      className: 'str',
                      render: (data, record) => {
                        return (
                          <span
                            style={{
                              color: record?.strength?.includes('과열') ? '#000' : '#a0a0a0',
                              fontWeight: record?.strength?.includes('과열') ? '600' : '500',
                            }}
                          >
                            {record?.strength}
                          </span>
                        );
                      },
                    },
                  ]}
                />
              </Col>
              <Col span={screenWidth < 1400 ? 24 : 12}>
                <ListTable
                  title={'BM 대비 (-) 비중'}
                  scrollHeight={427}
                  tableStyle={{ border: '1px solid #eff2fa' }}
                  dataSource={historyList?.minus}
                  columns={[
                    {
                      title: '종목',
                      align: 'center',
                      className: 'left',
                      render: (data, record) => {
                        return <span style={{ color: '#212121' }}>{record?.stock}</span>;
                      },
                    },
                    {
                      title: '추세',
                      align: 'center',
                      className: 'trend',
                      render: (data, record) => {
                        return (
                          <span
                            style={{
                              color:
                                record?.trend === '상승'
                                  ? colors.red
                                  : record?.trend === '하락'
                                  ? colors.main
                                  : colors.grey,
                              opacity: 0.6,
                            }}
                          >
                            {record?.trend}
                          </span>
                        );
                      },
                    },
                    {
                      title: '강도',
                      align: 'center',
                      className: 'str',
                      render: (data, record) => {
                        return (
                          <span
                            style={{
                              color: record?.strength?.includes('과열') ? '#000' : '#a0a0a0',
                              fontWeight: record?.strength?.includes('과열') ? '600' : '500',
                            }}
                          >
                            {record?.strength}
                          </span>
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </PageSection>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Default;
