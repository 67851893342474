import React from 'react';
import { useNavigate } from 'react-router';
import useScreen from '../../hooks/useScreen';
import { PageContainer } from '../../components/layout/page';
import PageSection from '../../components/layout/PageSection';
import TabList from '../../components/list/TabList';
import { PORTFOLIO_LIST_TABS, TREND_LABELS } from '../../common/defines';
import { PORTFOLIO_DUMMY } from '../../common/dummy';
import { colors } from '../../common/colors';
import { getPortfolios } from '../../apis/portfolio';

const Mobile = () => {
  const { is_mobile, screenHeight } = useScreen();
  const navigate = useNavigate();
  const [type, setType] = React.useState('국내포트');
  const [portfolios, setPortfolios] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');

  const loadPortfolios = async () => {
    try {
      await getPortfolios().then(({ data }) => {
        setPortfolios(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const list = React.useMemo(() => {
    return portfolios?.find((v) => v?.type === type)?.list?.filter((a) => a?.name?.includes(keyword)) || [];
  }, [portfolios, type, keyword]);

  const onRow = (record) => {
    return {
      onClick: () => {
        navigate(`/stock/detail`, {
          state: {
            record,
          },
        });
      },
    };
  };

  React.useEffect(() => {
    loadPortfolios();
  }, []);

  return (
    <PageContainer style={{ width: '100%' }}>
      <PageSection noHeader={true}>
        <TabList
          type={type}
          setType={setType}
          onRow={onRow}
          keyword={keyword}
          setKeyword={setKeyword}
          options={PORTFOLIO_LIST_TABS}
          dataSource={list}
          scrollHeight={screenHeight - 230}
          columns={[
            {
              title: '포트폴리오명',
              align: 'center',
              className: 'left',
              render: (data, record) => {
                return <span style={{ color: '#212121' }}>{record?.name}</span>;
              },
            },
            {
              title: '1M 수익률',
              align: 'center',
              render: (data, record) => {
                return (
                  <span style={{ color: record?.preMonth > 0 ? colors.red : colors?.main }}>{`${
                    record?.preMonth > 0 ? `+${record?.preMonth}%` : `${record?.preMonth}%`
                  }`}</span>
                );
              },
            },
            {
              title: '초과성과',
              align: 'center',
              width: 80,
              render: (data, record) => {
                return (
                  <span style={{ color: record?.over > 0 ? colors.red : colors?.main }}>{`${
                    record?.over > 0 ? `+${record?.over}%` : `${record?.over}%`
                  }`}</span>
                );
              },
            },
          ]}
        />
      </PageSection>
    </PageContainer>
  );
};

export default Mobile;
