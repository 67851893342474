import { restApi } from '..';

const FUTURES_URL = '/api/gift';

export const getVideoIds = async () => {
  try {
    const result = await restApi.get(`${FUTURES_URL}/video`);

    return result;
  } catch (error) {
    console.warn('getVideoIds with error', { error });
    throw error;
  }
};

export const getCharts = async (params) => {
  try {
    const result = await restApi.get(`${FUTURES_URL}/chart`, {
      params: {
        ...params,
        year: params?.year === 'all' ? undefined : params?.year,
      },
    });

    return result;
  } catch (error) {
    console.warn('getCharts with error', { error });
    throw error;
  }
};

export const getFutures = async () => {
  try {
    const result = await restApi.get(`${FUTURES_URL}/future`);

    return result;
  } catch (error) {
    console.warn('getFutures with error', { error });
    throw error;
  }
};

export const getNews = async () => {
  try {
    const result = await restApi.get(`${FUTURES_URL}/news`);

    return result;
  } catch (error) {
    console.warn('getNews with error', { error });
    throw error;
  }
};

export const getSign = async (params) => {
  try {
    const result = await restApi.get(`${FUTURES_URL}/sign`, { params });

    return result;
  } catch (error) {
    console.warn('getSign with error', { error });
    throw error;
  }
};

export const getYears = async () => {
  try {
    const result = await restApi.get(`${FUTURES_URL}/years`);

    return result;
  } catch (error) {
    console.warn('getYears with error', { error });
    throw error;
  }
};
