import React from 'react';
import { Caution, PageContainer } from '../../components/layout/page';
import PageSection from '../../components/layout/PageSection';
import styled from 'styled-components';
import { colors } from '../../common/colors';
import { BOARDS, BOARD_EVENT, BOARD_LIST, BOARD_RECRUIT } from '../../common/dummy';
import { Select, message } from 'antd';
import { BOARD_CATEGORIES, BOARD_ORDERS } from '../../common/defines';
import BoardList from '../../components/list/BoardList';
import { FormButton, FormSelect } from '../../components/form';
import { HORIZON_END } from '../../common/align';
import CustomRenderList from '../../components/list/CustomRenderList';
import BoardItem from '../../components/item/BoardItem';
import { useNavigate } from 'react-router';
import { getBoards, getTopBoards } from '../../apis/community';

import SignupModal from '../../components/modal/AuthModal/SignupModal';

const BoardTypeSelect = styled(Select)`
  .ant-select {
    border: none !important;
  }

  .ant-select-selector {
    border-radius: 0px;
    border: none !important;
  }

  .ant-select-selection-item {
    font-weight: 700;
  }
`;

const SelectSuffix = styled.img.attrs({
  draggable: false,
  alt: '이이콘',
  width: 8,
  height: 4,
  src: '/icon/arrow_mobile_select.png',
})``;

const Mobile = () => {
  const navigate = useNavigate();

  const [params, setParams] = React.useState({
    category: null,
    sort: 'createdAt',
    page: 0,
    limit: 10,
  });

  return (
    <>
      <PageContainer style={{ paddingBottom: '150px' }}>
        <PageSection title={'인기글 TOP 10'} wrapperStyle={{ padding: '13px 16px 20px' }}>
          <BoardList />
        </PageSection>

        <div style={{ padding: '19px 15px 16px', backgroundColor: '#eef3fa', position: 'sticky', top: 0, zIndex: 1 }}>
          <BoardTypeSelect
            style={{ width: '100%', height: 43 }}
            suffixIcon={<SelectSuffix />}
            options={BOARD_CATEGORIES}
            value={params?.category}
            onChange={(e) => {
              setParams((prev) => ({
                ...prev,
                category: e,
              }));
            }}
          />
        </div>

        <PageSection noHeader={true} bodyStyle={{ padding: 16, ...HORIZON_END, gap: 10 }}>
          <FormSelect
            options={BOARD_ORDERS}
            style={{ width: 101 }}
            value={params?.sort}
            onChange={(e) => {
              setParams((prev) => ({
                ...prev,
                sort: e,
              }));
            }}
          />
          <FormButton
            onClick={(e) => {
              navigate('/community/register');
            }}
          >
            글쓰기
          </FormButton>
        </PageSection>

        <PageSection noHeader={true} bodyStyle={{ paddingBottom: 20, position: 'relative' }}>
          <CustomRenderList
            restApi={async (params) => getBoards(params)}
            params={params}
            setParams={setParams}
            renderItem={(item, index) => {
              return (
                <BoardItem
                  item={item}
                  index={index}
                  onClick={() => {
                    navigate(`/community/detail/${item?.id}`, {
                      state: {
                        type: item?.category,
                      },
                    });
                  }}
                />
              );
            }}
          />
        </PageSection>
        <Caution style={{ marginTop: 20 }}>
          본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한, 제공되는
          모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을 지지 않습니다.
          모든 투자에 대한 책임은 본인에게 있습니다.
        </Caution>
      </PageContainer>
    </>
  );
};

export default Mobile;
