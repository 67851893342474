import React from 'react';
import * as C from './MobileDetailHeader.js.style';
import { useNavigate } from 'react-router';

const MobileDetailHeader = ({ title, sub_title, titleStyle, wrapperStyle }) => {
  const navigate = useNavigate();

  return (
    <C.Wrapper style={{ ...wrapperStyle }}>
      <C.BackBtn onClick={() => navigate(-1)}>
        <C.Icon />
      </C.BackBtn>

      <C.Title style={{ ...titleStyle }} sub_title={sub_title}>
        {title}
      </C.Title>
    </C.Wrapper>
  );
};

export default MobileDetailHeader;
