import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div`
  background-color: #fff;
`;

export const Header = styled.div`
  padding: 9px 20px;
  border-bottom: 1px solid ${colors.border};

  background-color: ${({ highlight }) => {
    return highlight && colors.main;
  }};
`;

export const Title = styled.div`
  width: 100%;

  & .title {
    color: ${({ highlight }) => {
      return highlight ? '#fff' : '#000';
    }};
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }

  & .code {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  & .type {
    padding-inline: 10px;
    height: 32px;
    border: 1px solid #e2e2e2;
    display: flex;
    align-items: center;

    color: #717171;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  & .date {
    color: #939393;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Body = styled.div``;
