import { decode } from 'base-64';
import { restApi } from '..';

const BOARD_URL = '/api/board';

const BOARD_URL2 = '/api/board/{id}';

export const getBoards = async (params) => {
  try {
    const result = await restApi.get(`${BOARD_URL}`, { params });

    return result;
  } catch (error) {
    console.warn('getBoards with error', { error });
    throw error;
  }
};

export const postBoard = async (body) => {
  try {
    const result = await restApi.post(`${BOARD_URL}`, body);

    return result;
  } catch (error) {
    console.warn('postBoard with error', { error });
    throw error;
  }
};

export const getBoardDetail = async (id) => {
  try {
    const result = await restApi.get(`${BOARD_URL}/${id}`, { params: { id } });

    return result;
  } catch (error) {
    console.warn('getBoardDetail with error', { error });
    throw error;
  }
};

export const putBoard = async (id, body) => {
  try {
    const result = await restApi.put(
      `${BOARD_URL}/${id}`,

      body,
    );

    return result;
  } catch (error) {
    console.warn('putBoard with error', { error });
    throw error;
  }
};

export const deleteBoard = async (id, body) => {
  try {
    const result = await restApi.delete(`${BOARD_URL}/${id}`, {
      data: body,
    });

    return result;
  } catch (error) {
    console.warn('deleteBoard with error', { error });
    throw error;
  }
};

export const getBoardCategories = async () => {
  try {
    const result = await restApi.get(`${BOARD_URL}/category`);

    return result;
  } catch (error) {
    console.warn('getBoardCategories with error', { error });
    throw error;
  }
};

export const getFixedBoard = async () => {
  try {
    const result = await restApi.get(`${BOARD_URL}/fix`);

    return result;
  } catch (error) {
    console.warn('getFixedBoard with error', { error });
    throw error;
  }
};

export const getTopBoards = async () => {
  try {
    const result = await restApi.get(`${BOARD_URL}/top`);

    return result;
  } catch (error) {
    console.warn('getTopBoards with error', { error });
    throw error;
  }
};
