import React from 'react';
import { PageContainer } from '../../../components/layout/page';
import PageSection from '../../../components/layout/PageSection';
import { Col, Row, Space } from 'antd';
import BoardList from '../../../components/list/BoardList';
import { COMMUNITY_TYPE_LABELS } from '../../../common/defines';
import CommunityDetail from '../../../components/layout/CommunityDetail';

const Default = ({ data, state, principal }) => {
  return (
    <PageContainer>
      <Row gutter={[24, 0]} type={'flex'}>
        <Col span={6}>
          <PageSection
            title={<span className={'title'}>인기글 TOP 10</span>}
            highlight={true}
            bodyStyle={{ padding: '30px 20px' }}
            wrapperStyle={{ height: '100%' }}
          >
            <BoardList />
          </PageSection>
        </Col>
        <Col span={18}>
          <PageSection
            title={<span className={'title'}>{state?.type}</span>}
            bodyStyle={{ padding: '24px 20px 50px', width: '100%' }}
          >
            <CommunityDetail data={data} principal={principal} />
          </PageSection>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Default;
