import { Carousel } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledCarousel = styled(Carousel)`
  .slick-dots.slick-dots-bottom {
    bottom: -20px;

    & > li {
      margin-inline: 4px !important;
      width: fit-content !important;
    }

    & .slick-active {
      & button {
        background-color: ${colors?.main};
      }
    }

    & button {
      background-color: #d9d9d9;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
`;

export const Btn = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  box-sizing: border-box;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  &.left {
    transform: rotate(180deg);
    left: -15px;
  }

  &.right {
    right: -15px;
  }
`;

export const Arrow = styled.img.attrs({
  draggable: false,
  width: 8,
  height: 16,
  alt: '아이콘',
  src: '/icon/arrow_white.png',
})``;
