import React from 'react';
import * as C from './ListTable.style';

const ListTable = ({
  dataSource = [],
  columns = [],
  showPagination = false,
  scrollHeight,
  light,
  wrapperStyle,
  onRow,
  title,
  params,
}) => {
  const pagination = {
    position: ['bottomCenter'],
  };

  return (
    <C.Wrapper style={wrapperStyle}>
      {title ? (
        <C.Header>
          <C.Title>{title}</C.Title>
        </C.Header>
      ) : null}
      <C.StyledTable
        dataSource={dataSource}
        style={{ border: '1px solid #eff2fa' }}
        columns={columns}
        pagination={showPagination ? pagination : false}
        scroll={{ y: scrollHeight }}
        light={light}
        onRow={onRow}
      />
    </C.Wrapper>
  );
};

export default ListTable;
