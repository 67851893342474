export const HORIZON_START = {
  display: 'flex',
  alignItems: 'center',
};

export const HORIZON_END = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

export const HORIZON_CENTER = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const HORIZON_BETWEEN = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const ALIGN_CENTER = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const VERTICAL_START = {
  display: 'flex',
  flexDirection: 'column',
};
