import styled, { css } from 'styled-components';
import { colors } from '../../../common/colors';
import { Button } from 'antd';

export const Container = styled.div``;

export const Header = styled.div`
  background-color: ${colors.main};
`;

export const Body = styled.div`
  ${({ is_login }) => {
    return is_login
      ? css`
          min-height: calc(100vh);
          background-color: #fff;
        `
      : css`
          min-height: calc(100vh - 180px);
        `;
  }};
  padding-top: 20px;
`;

export const Footer = styled.div``;

export const Inner = styled.div`
  width: 70%;
  margin: 0 auto;

  ${({ type }) => {
    return type === 'header'
      ? css`
          display: flex;
          align-items: center;
        `
      : type === 'body'
      ? css`
          height: 100%;
        `
      : css``;
  }}
`;

export const Logo = styled.img.attrs({
  draggable: false,
  src: '/logo/main.png',
  alt: '로고',
  width: 240,
  height: 80,
})`
  cursor: pointer;
`;

export const Btn = styled.div`
  cursor: pointer;
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;

  opacity: ${({ active }) => {
    return active ? '1' : '0.6';
  }};
`;

export const Caution = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  & > span {
    color: #898f98;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Login = styled.div`
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  gap: 4px;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: 0.3s all;

  color: #fff;
  font-size: 18px;
  font-weight: 500;

  &:hover {
    background-color: #fff;
    color: ${colors.main};
  }
`;

export const LoginForm = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${colors.border};
`;
