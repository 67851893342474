import React from 'react';
import { Caution, PageContainer } from '../../../components/layout/page';
import MobileDetailHeader from '../../../components/layout/MobileDetailHeader.js';
import { COMMUNITY_TYPE_LABELS } from '../../../common/defines';
import CommunityDetail from '../../../components/layout/CommunityDetail/index.js';

const Mobile = ({ data, state, principal }) => {
  return (
    <PageContainer style={{ paddingBottom: 120, width: '100%' }}>
      <MobileDetailHeader title={<span className={'title'}>{state?.type}</span>} />
      <CommunityDetail data={data} principal={principal} />
      <Caution style={{ marginTop: 20 }}>
        본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한, 제공되는
        모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을 지지 않습니다. 모든
        투자에 대한 책임은 본인에게 있습니다.
      </Caution>
    </PageContainer>
  );
};

export default Mobile;
