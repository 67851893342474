import { List } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: 1px solid #828282;
`;

export const StyledList = styled(List)`
  .ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  .ant-list-items > div {
    border-top: none;
  }
`;

export const StyledListItem = styled(List.Item)``;
