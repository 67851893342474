import React from 'react';
import * as C from './CustomRenderList.style';
import { StyledList } from '../BoardList/BoardList';
import { Pagination } from 'antd';
import { getTopBoards } from '../../../apis/community';
import { HORIZON_CENTER } from '../../../common/align';

const CustomRenderList = ({ wrapperStyle, params, setParams, restApi, onRow, columns, renderItem = () => {} }) => {
  const [dataSource, setDataSource] = React.useState();
  const [data, setData] = React.useState([]);
  const [topData, setTopData] = React.useState([]);

  const loadData = async () => {
    try {
      const { data: tops } = await getTopBoards();

      const addTops = tops?.map((v) => ({ ...v, is_top: true }));
      setTopData(addTops);

      await restApi(params).then(({ data }) => {
        setDataSource(data);

        setData([...addTops, ...data?.content]);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const rowClassName = (record) => {
    if (record?.is_top) {
      return 'is_top';
    }
    return '';
  };

  const pagination = {
    total: dataSource?.totalElements + topData?.length,
    pageSize: params?.limit + topData?.length,
    current: params?.page + 1,
    onChange: (page) => {
      setParams((prev) => ({
        ...prev,
        page: page - 1,
      }));
    },
  };

  React.useEffect(() => {
    loadData();
  }, [params]);

  return (
    <C.Wrapper style={{ ...wrapperStyle }}>
      <C.StyledList
        dataSource={data}
        // pagination={pagination}
        renderItem={(item, index) => {
          return renderItem(item, index);
        }}
      />
      <div style={{ ...HORIZON_CENTER, marginTop: 20 }}>
        <Pagination {...pagination} />
      </div>
    </C.Wrapper>
  );
};

export default CustomRenderList;
