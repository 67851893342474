import React from 'react';
import * as C from './PeriodButtonSet.style';
import dayjs from 'dayjs';
import useScreen from '../../../hooks/useScreen';
import { getYears } from '../../../apis/futures';

const PeriodButtonSet = ({ wrapperStyle, buttonStyle, data, setData }) => {
  const { is_mobile } = useScreen();
  const [year, setYear] = React.useState([]);

  const loadDate = async () => {
    try {
      await getYears().then(({ data }) => {
        const yearArr = ['all', ...data]?.map((v, i) => {
          return { type: i === 0 ? 'all' : i, value: v };
        });

        setYear(yearArr);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    loadDate();
  }, []);

  return (
    <C.Wrapper is_mobile={is_mobile} style={{ ...wrapperStyle }}>
      {year?.map((v, i) => {
        const active = data === v?.type;
        return (
          <C.Btn
            key={`PERIOD_BTN_${i}`}
            is_mobile={is_mobile}
            active={active}
            onClick={() => {setData(v);}}
            style={{ ...buttonStyle }}
          >
            {v?.type === 'all' ? '전 기간' : v?.value}
          </C.Btn>
        );
      })}
    </C.Wrapper>
  );
};

export default PeriodButtonSet;
