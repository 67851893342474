import React from 'react';
import useScreen from '../../hooks/useScreen';
import Mobile from './Mobile';
import Default from './Default';
import { getFutures, getVideoIds } from '../../apis/futures';

const FuturesIndex = () => {
  const { is_mobile } = useScreen();
  const [videoIds, setVideoIds] = React.useState([]);

  const loadVideoData = async () => {
    try {
      await getVideoIds().then(({ data }) => {
        setVideoIds(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    loadVideoData();
  }, []);

  if (is_mobile) {
    return <Mobile videoIds={videoIds} />;
  } else {
    return <Default videoIds={videoIds} />;
  }
};

export default FuturesIndex;
