import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  ${({ is_mobile }) => {
    return is_mobile ? css`` : css``;
  }};
`;

export const Header = styled.div`
  background: #f5f5f5;

  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          padding: 16px 16px 12px;
        `
      : css`
          padding: 17px 20px 17px;
          border-bottom: 1px solid #e9e9e9;
        `;
  }}
`;

export const Title = styled.span`
  color: #000;
  font-weight: 500;
  line-height: normal;

  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          font-weight: 700;
          font-size: 16px;
          letter-spacing: -0.48px;
        `
      : css`
          font-size: 18px;
          letter-spacing: -0.54px;
        `;
  }}
`;

export const SubHeader = styled.div`
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 16px;
  padding-block: 8.5px;

  @media (max-width: 767px) {
    // padding-bottom: 16px;
  }

  & > div {
    display: flex;
    align-items: center;
    ${({ is_mobile }) => {
      return is_mobile
        ? css`
            border-bottom: 1px solid #e9e9e9;
            justify-content: space-between;
          `
        : css``;
    }}
  }
`;

export const SubInfo = styled.span`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          color: #ababab;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.42px;
        `
      : css`
          color: #555;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.48px;
        `;
  }}
`;

export const Divider = styled.div`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          display: none;
        `
      : css`
          height: 12px;
          width: 1px;
          background-color: #d3d3d3;
          margin-inline: 10px;
        `;
  }}
`;

// export const Outer = styled.div`
//   ${({ is_mobile }) => {
//     return is_mobile
//       ? css`
//           min-height: 100%;
//         `
//       : css`
//           min-height: calc(100vh - 800px);
//         `;
//   }}
// `;

export const Body = styled.div`
  overflow-y: scroll;

  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          padding: 12px 16px 104px;
          border-bottom: 1px solid #c9c9c9;
          // max-height: calc(100vh - 400px);
          // min-height: calc(100vh - 400px);
        `
      : css`
          padding: 26px 20px 218px;
          // max-height: calc(100vh - 480px);
          // min-height: calc(100vh - 480px);
          border-bottom: 1px solid #c9c9c9;
        `;
  }}
`;

export const Hit = styled.span`
  font-size: 15px;
  letter-spacing: -0.62px;
  font-weight: 500;
`;

export const Footer = styled.div`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          padding: 20px 15px;
        `
      : css`
          display: flex;
          justify-content: flex-end;
          padding-top: 20px;
        `;
  }}
`;
