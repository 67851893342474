import { Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const CustomInput = styled(Input).attrs({ placeholder: '검색어를 입력하세요.' })`
  border-radius: 0px;
  border-inline: none;
  height: 40px;
`;

export const Icon = styled.img.attrs({
  draggable: false,
  alt: '아이콘',
  width: 16,
  height: 16,
  src: '/icon/search.png',
})``;
