import React from 'react';
import * as C from './SummaryTable.style';
import dayjs from 'dayjs';
import useScreen from '../../../hooks/useScreen';

const SummaryTable = ({ dataSource }) => {
  const { is_mobile } = useScreen();

  return (
    <C.Wrapper>
      {is_mobile ? (
        <>
          <C.StyledTable is_mobile style={{ borderCollapse: 'collapse' }}>
            <C.Tr is_mobile>
              <C.Th is_mobile />
              <C.Th is_mobile className={'ai_th'}>
                <C.Label className={'ai'} is_mobile>
                  <div className={'index'} />
                  <span>AI 수익률</span>
                </C.Label>
              </C.Th>
              <C.Th is_mobile className={'bm_th'}>
                <C.Label className={'bm'} is_mobile>
                  <div className={'index'} />
                  <span>BM 수익률</span>
                </C.Label>
              </C.Th>
            </C.Tr>
            {dataSource?.map((v, i) => {
              return (
                <C.Tr is_mobile>
                  <C.Th is_mobile className={'year'}>
                    {v?.year}
                  </C.Th>
                  <C.Td is_mobile>{`${v?.aiRate}%`}</C.Td>
                  <C.Td is_mobile>{`${v?.bmRate}%`}</C.Td>
                </C.Tr>
              );
            })}
          </C.StyledTable>
        </>
      ) : (
        <C.StyledTable style={{ borderCollapse: 'collapse' }}>
          <C.Tr>
            <C.Th />
            {dataSource?.map((v, i) => {
              return <C.Th key={`SUMMARY_TABLE_TH_${i}`}>{v?.year}</C.Th>;
            })}
          </C.Tr>

          <C.Tr style={{ borderBottom: '1px solid #c2c2c2' }}>
            <C.Td className={'ai_td'}>
              <C.Label className={'ai'}>
                <div className={'index'} />
                <span>AI 수익률</span>
              </C.Label>
            </C.Td>
            {dataSource?.map((v, i) => {
              return <C.Td key={`AI_SUMMARY_TD_${i}`}>{`${v?.aiRate}%`}</C.Td>;
            })}
          </C.Tr>

          <C.Tr>
            <C.Td className={'bm_td'}>
              <C.Label className={'bm'}>
                <div className={'index'} />
                <span>BM 수익률</span>
              </C.Label>
            </C.Td>
            {dataSource?.map((v, i) => {
              return <C.Td key={`BM_SUMMARY_TD_${i}`}>{`${v?.bmRate}%`}</C.Td>;
            })}
          </C.Tr>
        </C.StyledTable>
      )}
    </C.Wrapper>
  );
};

export default SummaryTable;
