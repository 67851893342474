// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';
import { STOCK_CODES } from '../../../common/defines';
import { TraidingView } from './TradingViewWidget.style';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';

let tvScriptLoadingPromise;

export default function TradingViewWidget({ target }) {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById('tradingview_815e7') && 'TradingView' in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: `${STOCK_CODES[target?.market]}:${target?.code}`,
          timezone: 'Etc/UTC',
          theme: 'light',
          style: '1',
          locale: 'kr',
          enable_publishing: true,
          withdateranges: true,
          range: 'YTD',
          hide_side_toolbar: true,
          allow_symbol_change: true,
          details: true,
          hotlist: true,
          calendar: true,
          show_popup_button: true,
          popup_width: '1000',
          popup_height: '650',
          container_id: 'tradingview_815e7',
        });
      }
    }
  }, [target]);

  React.useEffect(() => {
    document.querySelector('.tradingview-widget-copyright').style.display = 'none';
  }, []);

  return (
    // <AdvancedRealTimeChart
    //   width={'100%'}
    //   height={'calc(100% - 130px)'}
    //   symbol="BINANCE:BTCUSDT"
    //   allow_symbol_change={true}
    //   interval="D"
    //   calendar={true}
    //   timezone="Asia/Seoul"
    //   style="1"
    //   locale="kr"
    //   details={true}
    //   toolbar_bg="#f1f3f6"
    //   container_id="tradingview_dcf24"
    // ></AdvancedRealTimeChart>
    <TraidingView className="tradingview-widget-container" style={{ height: '100%', width: '100%' }}>
      <div id="tradingview_815e7" style={{ height: 'calc(100% - 32px)', width: '100%' }} />
      <div className="tradingview-widget-copyright">
        <a href="https://kr.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">트레이딩뷰에서 모든 시장 추적</span>
        </a>
      </div>
    </TraidingView>
  );
}
