import React from 'react';
import * as C from './SummaryChart.js.style';
import { PORT_SUMMARY } from '../../../common/dummy3';
import { colors } from '../../../common/colors';
import SummaryTable from '../../list/SummaryTable';

const SummaryChart = ({ dataSource, comment1, comment2, wrapperStyle, target, onClose }) => {
  const data = React.useMemo(() => {
    const ai = dataSource?.map((v) => v?.aiRate);

    const bm = dataSource?.map((v) => v?.bmRate);

    const year = dataSource?.map((v) => v?.year);

    return { ai, bm, year };
  }, [dataSource]);

  return (
    <C.Wrapper style={{ ...wrapperStyle }}>
      <C.Header>
        <C.Label>AI 전략 및 성과 요약</C.Label>
        <C.CloseBtn onClick={onClose}>
          <C.Icon />
        </C.CloseBtn>
      </C.Header>

      <C.Summary>
        <div className={'sentence'}>
          <span>∙</span>
          <span>{comment1}</span>
        </div>
        <div className={'sentence'}>
          <span>∙</span>
          <span>{comment2}</span>
        </div>
      </C.Summary>

      <C.StyledChart
        height={250}
        type="bar"
        series={[
          {
            name: 'AI',
            data: data?.ai,
            color: colors.red,
          },
          {
            name: 'BM',
            data: data?.bm,
            color: '#DDDEDE',
          },
        ]}
        options={{
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              formatter: function (y) {
                return y.toFixed(0) + '%';
              },
            },
          },
          xaxis: {
            categories: data?.year,
            labels: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000'],
              fontWeight: ['400'],
            },
            formatter: function (value) {
              return `${value}%`;
            },
          },

          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '70%',
              endingShape: 'rounded',
            },
          },
        }}
      ></C.StyledChart>
      <SummaryTable dataSource={dataSource} />
    </C.Wrapper>
  );
};

export default SummaryChart;
