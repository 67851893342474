import { Button, Input, message } from 'antd';
import styled, { css } from 'styled-components';
import { colors } from '../../common/colors';
import { restApi, setToken } from '../../apis';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { clearPrincipal, me, setPrincipal } from '../../data/auth';
import SignupModal from '../../components/modal/AuthModal/SignupModal';
import base64 from 'base-64';
import qs from 'qs';
import React from 'react';
import useScreen from '../../hooks/useScreen';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          min-height: 100vh;
          background-color: #fff;
          padding-inline: 16px;
        `
      : css`
          min-height: calc(100vh - 200px);
        `;
  }}
`;

const Logo = styled.img.attrs({ draggable: false, src: '/logo/main_big.png', alt: '로고' })`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          width: 200px;
          height: 51px;
          margin-bottom: 29px;
        `
      : css`
          width: 504px;
          height: 128px;
          margin-bottom: 54px;
        `;
  }}
`;

const Signname = styled(Input)`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          width: 100%;
          height: 50px;
          border-radius: 0px;
          margin-bottom: 8px;
        `
      : css`
          border-radius: 0px;
          width: 420px;
          height: 60px;
          margin-bottom: 12px;
          .ant-input-prefix {
            & > span {
              border-right: 1px solid #c9c9c9;
              margin-right: 4px;
            }
          }
        `;
  }}
`;

const Password = styled(Input.Password)`
  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          width: 100%;
          height: 50px;
          border-radius: 0px;
        `
      : css`
          border-radius: 0px;
          width: 420px;
          height: 60px;
          .ant-input-prefix {
            & > span {
              border-right: 1px solid #c9c9c9;
              margin-right: 4px;
            }
          }
        `;
  }}
`;

const Prefix = styled.span`
  display: inline-block;
  width: 100px;

  color: #636363;

  font-size: 14px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.5px;
`;

const Btn = styled(Button)`
  background-color: ${colors.main} !important;
  color: #fff !important;
  text-align: center;
  font-size: 13.3px;
  font-weight: 700;
  line-height: normal;

  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          margin-top: 19px;
          height: 60px;
          width: 100% !important;
          border-radius: 0px;
          font-size: 14px;
        `
      : css`
          border-radius: 0px;
          margin-top: 30px;
          height: 60px;
          font-size: 16px;
        `;
  }};
`;

const LoginText = styled.div`
  text-align: center;

  & > span {
    font-size: 18px;
    line-height: 25px;

    letter-spacing: -0.42px;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 15px;
    }

    & > b {
      color: ${colors.main};
    }
  }
`;

const LoginIndex = () => {
  const { principal } = useSelector((s) => s?.auth, shallowEqual);
  const { is_mobile } = useScreen();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [account, setAccount] = React.useState({
    username: '',
    password: '',
  });
  const usernameRef = React.useRef();

  const onInputChange = (e) => {
    setAccount((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = async () => {
    try {
      if (!account?.username) {
        message.error('아이디를 입력하세요.');
        return;
      }
      if (!account?.password) {
        message.error('비밀번호를 입력하세요.');
        return;
      }

      const authHeader = 'Basic ' + base64.encode(`maimai:maimai!@`);
      await restApi
        .post(
          `/oauth/token`,
          qs.stringify({
            username: account?.username,
            password: account?.password,
            grant_type: 'password',
          }),
          {
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then(async (res) => {
          setToken(res?.data);
          await dispatch(
            me({
              headers: {
                authorization: `Bearer ${res?.data?.access_token}`,
              },
            }),
          );

          setAccount((prev) => ({
            ...prev,
            username: '',
            password: '',
          }));
          message.success('로그인이 완료되었습니다.');
          navigate('/consultation');
        })
        .catch((rej) => {
          if (rej?.response?.data?.status === 401) {
            message.error('계정정보가 일치하지 않습니다.');
          }
        });
    } catch (error) {
      console.warn({ error });
    }
  };

  const handleLogout = () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) return;
    setToken({});
    dispatch(clearPrincipal());

    navigate('/consultation');
  };

  React.useEffect(() => {
    if (usernameRef?.current) {
      usernameRef?.current?.focus();
    }
  }, []);

  return (
    <Wrapper is_mobile={is_mobile}>
      <Logo is_mobile={is_mobile} principal={principal} />

      {principal ? (
        <LoginText>
          <span>
            현재 <b>로그인 중</b>입니다.
          </span>
          <br />
          <span>로그아웃 하시겠습니까?</span>
        </LoginText>
      ) : (
        <>
          <Signname
            is_mobile={is_mobile}
            ref={usernameRef}
            prefix={<Prefix is_mobile={is_mobile}>아이디</Prefix>}
            value={account?.username}
            name={'username'}
            onChange={onInputChange}
            onPressEnter={handleLogin}
          />
          <Password
            is_mobile={is_mobile}
            prefix={<Prefix is_mobile={is_mobile}>비밀번호</Prefix>}
            value={account?.password}
            name={'password'}
            onChange={onInputChange}
            onPressEnter={handleLogin}
          />
        </>
      )}
      <Btn
        style={{ width: 420 }}
        is_mobile={is_mobile}
        onClick={() => {
          if (principal) {
            handleLogout();
            return;
          }
          handleLogin();
        }}
      >
        {principal ? '로그아웃' : '로그인하기'}
      </Btn>
    </Wrapper>
  );
};

export default LoginIndex;
