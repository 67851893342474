import React from 'react';
import useScreen from '../../../hooks/useScreen';
import * as C from './CommunityDetail.style';
import dayjs from 'dayjs';
import { FormButton } from '../../form';
import { useNavigate } from 'react-router';
import { deleteBoard } from '../../../apis/community';
import { message } from 'antd';
import AuthModal from '../../modal/AuthModal';
import { clearPrincipal } from '../../../data/auth';
import { setToken } from '../../../apis';
import { useDispatch } from 'react-redux';
import { colors } from '../../../common/colors';
import styled from 'styled-components';
import { HORIZON_START } from '../../../common/align';

const Volume = styled.img.attrs({ draggable: false, src: '/icon/volume.png', alt: '아이콘', width: 20, height: 20 })`
  margin-right: 10px;
`;

const CommunityDetail = ({ data, principal }) => {
  const { is_mobile, screenWidth } = useScreen();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);

  const onDelete = async () => {
    try {
      const form = { ...data };

      delete form?.password;

      await deleteBoard(data?.id, { password })
        .then(() => {
          message.success('삭제가 완료되었습니다.');
          navigate('/community');
          setPassword('');
        })
        .catch((rej) => {
          message.error('삭제를 실패했습니다.');
        });
    } catch (error) {
      console.warn({ error });
    }
  };

  // React.useEffect(() => {
  //   dispatch(clearPrincipal());
  //   setToken({});
  // }, []);

  return (
    <>
      <C.Wrapper is_mobile={is_mobile}>
        <C.Header is_mobile={is_mobile} style={{ display: 'flex', alignItems: 'center' }}>
          {data?.isFix ? <Volume /> : null}
          <C.Title is_mobile={is_mobile}>{data?.title}</C.Title>
        </C.Header>
        <C.SubHeader is_mobile={is_mobile}>
          <div>
            <C.SubInfo is_mobile={is_mobile}>{data?.writer}</C.SubInfo>
            <C.Divider is_mobile={is_mobile} />
            <C.SubInfo is_mobile={is_mobile}>{dayjs(data?.createdAt)?.format('YYYY.MM.DD')}</C.SubInfo>
          </div>
          <C.Hit>조회수 : {data?.viewCount}</C.Hit>
        </C.SubHeader>

        {/* <C.Outer style={{ maxWidth: '100%' }} is_mobile={is_mobile}> */}
        <C.Body
          is_mobile={is_mobile}
          dangerouslySetInnerHTML={{
            __html: `<style>.container img {max-width: 100%;} .container p {word-wrap: break-word;white-space: pre-wrap;}</style><div class='container'>${data?.content}</div>`,
          }}
        />

        <C.Footer is_mobile={is_mobile} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <FormButton
            style={{
              width: is_mobile ? '100%' : 79,
              height: is_mobile ? 40 : 32,
              marginRight: 10,
              backgroundColor: '#fff',
            }}
            onClick={() => navigate('/community')}
          >
            <span style={{ color: colors?.main }}>목록</span>
          </FormButton>

          <FormButton
            // className={'blur'}
            style={{ width: is_mobile ? '100%' : 79, height: is_mobile ? 40 : 32, marginRight: 10 }}
            onClick={() =>
              navigate(`/community/register`, {
                state: {
                  id: data?.id,
                },
              })
            }
          >
            수정
          </FormButton>
          <FormButton
            className={'delete'}
            style={{
              width: is_mobile ? '100%' : 79,
              height: is_mobile ? 40 : 32,
            }}
            onClick={() => {
              if (data?.password) {
                setModalOpen(true);
                return;
              }
              onDelete();
            }}
          >
            삭제
          </FormButton>
        </C.Footer>
        {/* </C.Outer> */}
      </C.Wrapper>
      <AuthModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        value={password}
        setValue={setPassword}
        onOk={onDelete}
      />
    </>
  );
};

export default CommunityDetail;
