import React from 'react';
import useScreen from '../../../hooks/useScreen';
import * as C from './SearchInput.style';

const SearchInput = ({ keyword, setKeyword }) => {
  const { is_mobile } = useScreen();
  return (
    <C.Wrapper>
      {is_mobile ? (
        <C.CustomInput
          allowClear
          prefix={<C.Icon />}
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
      ) : (
        <C.CustomInput
          suffix={<C.Icon />}
          allowClear
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
      )}
    </C.Wrapper>
  );
};

export default SearchInput;
