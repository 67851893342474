import React from 'react';
import * as C from './ConsultResultSet';
import { Col, Row } from 'antd';
import { FormButton, FormSelect, SelectSuffixIcon } from '../../form';
import { AI_CONSULT_FILTER1, AI_CONSULT_FILTER2, AI_CONSULT_FILTER3 } from '../../../common/defines';
import ListTable from '../../list/ListTable';
import { ASK_DUMMY } from '../../../common/dummy';
import { colors } from '../../../common/colors';
import useScreen from '../../../hooks/useScreen';
import { getCaseNo } from '../../../utils';

const ConsultResultSet = ({ consultTypes, setConsultTypes, target, list, tableStyle }) => {
  const { is_mobile, screenWidth } = useScreen();
  const [filterValue, setFilterValue] = React.useState(consultTypes);
  const [tableHeight, setTableHeight] = React.useState(0);
  const imgRef = React.useRef();
  const result_case = React.useMemo(() => {
    return target?.case1PList?.find(
      (v) => v?.caseNo === getCaseNo(consultTypes?.case1, consultTypes?.case2, consultTypes?.case3),
    );
  }, [target, consultTypes]);

  const setValues = () => {
    setConsultTypes({ ...filterValue });
  };

  React.useEffect(() => {
    setFilterValue((prev) => ({
      ...prev,
      case1: 1,
      case2: 1,
      case3: 1,
    }));
    setConsultTypes((prev) => ({
      ...prev,
      case1: 1,
      case2: 1,
      case3: 1,
    }));
  }, [target]);

  React.useEffect(() => {
    if (imgRef?.current) {
      setTableHeight(imgRef?.current?.offsetHeight);
    }
  }, [screenWidth, imgRef?.current]);

  return (
    <C.Wrapper>
      <C.FilterSet>
        <Row gutter={[16, 16]}>
          <Col span={7}>
            <FormSelect
              value={filterValue?.case1}
              options={AI_CONSULT_FILTER1}
              style={{ width: '100%' }}
              suffixIcon={<SelectSuffixIcon />}
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  case1: e,
                }));
              }}
            />
          </Col>
          <Col span={7}>
            <FormSelect
              value={filterValue?.case2}
              options={AI_CONSULT_FILTER2}
              style={{ width: '100%' }}
              suffixIcon={<SelectSuffixIcon />}
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  case2: e,
                }));
              }}
            />
          </Col>
          <Col span={7}>
            <FormSelect
              value={filterValue?.case3}
              options={AI_CONSULT_FILTER3}
              style={{ width: '100%' }}
              suffixIcon={<SelectSuffixIcon />}
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  case3: e,
                }));
              }}
            />
          </Col>
          <Col span={3}>
            <FormButton style={{ width: '100%' }} onClick={setValues}>
              적용하기
            </FormButton>
          </Col>
        </Row>
      </C.FilterSet>

      <C.Opinion>
        <div className={'sentence'}>
          <span>∙</span>
          <span>{result_case?.case1}</span>
        </div>
        <div className={'sentence'}>
          <span>∙</span>
          <span>{result_case?.case2}</span>
        </div>
      </C.Opinion>

      <C.ContentSet>
        <Row gutter={[12, 12]}>
          <Col span={screenWidth < 1400 ? 12 : 8}>
            <C.Img src={`/img/trading${target?.picType2}.png`} ref={imgRef} />
          </Col>
          <Col span={screenWidth < 1400 ? 12 : 8}>
            <C.Img src={`/img/trend${target?.picType3}.png`} />
          </Col>
          <Col span={screenWidth < 1400 ? 24 : 8}>
            <ListTable
              wrapperStyle={{ border: `1px solid ${colors.border}`, height: tableHeight }}
              tableStyle={{ height: '100%' }}
              dataSource={list}
              showPagination={false}
              scrollHeight={tableHeight - 40}
              columns={[
                {
                  title: '순위',
                  align: 'center',
                  width: 80,
                  render: (data, record, index) => {
                    return <span>{index + 1}</span>;
                  },
                },
                {
                  title: '연관 키워드(텍스트마이닝)',
                  align: 'center',

                  render: (data, record, index) => {
                    return <span style={{ color: '#a0a0a0' }}>{record?.share}</span>;
                  },
                },
                {
                  title: '점유율',
                  align: 'center',
                  width: 80,
                  render: (data, record, index) => {
                    return <span style={{ color: colors.main }}>{`${record?.rank}%`}</span>;
                  },
                },
              ]}
            />
          </Col>
        </Row>
      </C.ContentSet>
    </C.Wrapper>
  );
};

export default React.memo(ConsultResultSet);
