import React from 'react';
import { Modal } from 'antd';
import * as C from './AuthModal.style';
import { FormPassword } from '../../form';

const AuthModal = ({ open, onCancel, value, setValue, onOk }) => {
  return (
    <Modal open={open} onCancel={onCancel} centered closeIcon={null} cancelText={'취소'} okText={'확인'} onOk={onOk}>
      <C.ModalView>
        <C.Header>
          <C.Title>게시글 삭제</C.Title>
        </C.Header>
        <C.Body>
          <FormPassword
            placeholder={'비밀번호 입력'}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </C.Body>
      </C.ModalView>
    </Modal>
  );
};

export default AuthModal;
