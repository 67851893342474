import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

export const BackBtn = styled.div`
  margin-right: 8px;
  cursor: pointer;
`;

export const Icon = styled.img.attrs({
  draggable: false,
  alt: '이미지',
  src: '/icon/arrow_back.png',

  width: 24,
  height: 24,
})``;

export const Title = styled.div`
  width: 100%;

  & .title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }

  & .date {
    color: #bebebe;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }

  ${({ sub_title }) => {
    return (
      sub_title &&
      css`
        &::after {
          content: ' ${sub_title}';
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
        }
      `
    );
  }}
`;
