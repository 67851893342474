import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Caution, PageContainer, TabNav, TabNavItem } from '../../../components/layout/page';
import PageSection from '../../../components/layout/PageSection';
import MobileDetailHeader from '../../../components/layout/MobileDetailHeader.js';
import { HORIZON_START, HORIZON_BETWEEN, ALIGN_CENTER } from '../../../common/align.jsx';
import styled from 'styled-components';
import PeriodButtonSet from '../../../components/filter/PeriodButtonSet/index.js';
import { FormButton, FormDatePicker } from '../../../components/form/index.js';
import TabList from '../../../components/list/TabList/index.js';
import { RATE_LIST_TABS } from '../../../common/defines.js';
import { BM_LIST } from '../../../common/dummy2.js';
import { colors } from '../../../common/colors.js';
import useScreen from '../../../hooks/useScreen.js';
import dayjs from 'dayjs';
import { getPortfolioDetail } from '../../../apis/portfolio/index.js';
import SummaryTable from '../../../components/list/SummaryTable/index.js';
import EarningRateChart from '../../../components/chart/EarningRateChart.js';

const Summary = styled.div`
  margin-bottom: 29px;

  & > .sentence {
    display: flex;
    color: #444;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    &:nth-of-type(1) {
      margin-bottom: 20px;
    }

    & > span:nth-of-type(1) {
      margin-right: 10px;
    }
  }
`;

const StockDetailIndex = () => {
  const {
    state: { record },
  } = useLocation();
  const { is_mobile } = useScreen();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState('summary');
  const [type, setType] = React.useState('PLUS');
  const [year, setYear] = React.useState({ type: 'all', value: 'all' });
  const [date, setDate] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [dateFilter, setDateFilter] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [histories, setHistories] = React.useState([]);
  const [detail, setDetail] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const historyList = React.useMemo(() => {
    const PLUS = histories?.filter((v) => v?.type === 'PLUS');
    const MINUS = histories?.filter((v) => v?.type === 'MINUS');

    return { PLUS, MINUS };
  }, [histories]);

  const loadDetail = async () => {
    try {
      setLoading(true);
      await getPortfolioDetail(record?.id, { year: year?.value }).then(({ data }) => {
        setDetail(data);
      });
    } catch (error) {
      console.warn({ error });
    } finally {
      setLoading(false);
    }
  };

  const loadHistories = async () => {
    try {
      await getPortfolioDetail(record?.id, { date }).then(({ data }) => {
        setHistories(data?.portfolioHistoryList);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  React.useEffect(() => {
    if (!is_mobile) {
      navigate('/stock');
    }
  }, [is_mobile]);

  React.useEffect(() => {
    if (record?.id) {
      loadDetail();
    }
  }, [record, year]);

  React.useEffect(() => {
    if (record?.id) {
      loadHistories();
    }
  }, [record, date]);

  return (
    <PageContainer style={{ paddingBottom: 120 }}>
      <MobileDetailHeader
        wrapperStyle={{ marginBottom: 8 }}
        titleStyle={{ ...HORIZON_BETWEEN }}
        title={
          <>
            <span className={'title'}>{detail?.name}</span>
            <span className={'date'}>{dayjs(detail?.date)?.format('YYYY.MM.DD')} 기준</span>
          </>
        }
      />

      <PageSection noHeader={true}>
        <PeriodButtonSet
          wrapperStyle={{
            padding: '12px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
          }}
          data={year?.type}
          setData={(v) => {
            setYear(v);
          }}
        />
        <div style={{ width: '100%', aspectRatio: 1 / 0.54, backgroundColor: '#fff', ...ALIGN_CENTER }}>
          <EarningRateChart data={detail?.dailyPdxs} year={year} loading={loading} />
        </div>
      </PageSection>

      <TabNav onChange={setTab} indicatorSize={0}>
        <TabNavItem tab={'AI 성과요약'} key={'summary'}>
          <PageSection noHeader={true} bodyStyle={{ padding: '29px 16px 20px' }}>
            <Summary>
              <div className="sentence">
                <span>∙</span>
                <span>{detail?.comment1}</span>
              </div>
              <div className="sentence">
                <span>∙</span>
                <span>{detail?.comment2}</span>
              </div>
            </Summary>

            <SummaryTable dataSource={detail?.performanceSummaryList} />
          </PageSection>
        </TabNavItem>
        <TabNavItem tab={'포트 종목분석'} key={'analysis'}>
          <PageSection noHeader={true} bodyStyle={{ padding: '20px 16px', ...HORIZON_START, gap: 7, marginBottom: 8 }}>
            <FormDatePicker
              style={{ flex: 1 }}
              placeholder={'조회일'}
              allowClear={false}
              value={dayjs(dateFilter, 'YYYY-MM-DD', 'YYYY-MM-DD')}
              onChange={(e) => {
                setDateFilter(dayjs(e)?.format('YYYY-MM-DD'));
              }}
            />
            <FormButton style={{ width: 79 }} onClick={() => setDate(dateFilter)}>
              검색
            </FormButton>
          </PageSection>

          <PageSection noHeader={true}>
            <TabList
              type={type}
              setType={setType}
              options={RATE_LIST_TABS}
              dataSource={historyList?.[type]}
              showSearch={false}
              scrollHeight={288}
              columns={[
                {
                  title: '종목',
                  ellipsis: true,
                  align: 'center',
                  render: (data, record) => {
                    return <span style={{ fontWeight: '700' }}>{record?.stock}</span>;
                  },
                },
                {
                  title: '추세',
                  align: 'center',
                  width: 100,
                  render: (data, record) => {
                    return (
                      <span
                        style={{
                          color:
                            record?.trend === '상승'
                              ? colors.red
                              : record?.trend === '하락'
                              ? colors?.main
                              : colors.grey,
                        }}
                      >
                        {record?.trend}
                      </span>
                    );
                  },
                },
                {
                  title: '강도',
                  align: 'center',
                  width: 100,

                  render: (data, record) => {
                    return (
                      <span
                        style={{
                          fontWeight: record?.strength?.includes('과열') ? '700' : '500',
                          color: record?.strength?.includes('과열') ? '#000' : '#a0a0a0',
                        }}
                      >
                        {record?.strength}
                      </span>
                    );
                  },
                },
              ]}
            />
          </PageSection>
        </TabNavItem>
      </TabNav>
      <Caution style={{ paddingTop: 11 }}>
        본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한, 제공되는
        모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을 지지 않습니다. 모든
        투자에 대한 책임은 본인에게 있습니다.
      </Caution>
    </PageContainer>
  );
};

export default StockDetailIndex;
