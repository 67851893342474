import { Button, Checkbox, DatePicker, Descriptions, Input, Radio, Select } from 'antd';
import styled, { css } from 'styled-components';
import { colors } from '../../common/colors';

export const FormSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 0px;
    border: ${({ noBorder }) => {
      return noBorder && 'none !important';
    }};
    .ant-select-selection-item {
      ${({ isHeader }) => {
        return (
          isHeader &&
          css`
            font-size: 24px;
            font-weight: 700;
          `
        );
      }};
    }
  }
`;

export const SelectSuffixIcon = styled.img.attrs({
  draggable: false,
  alt: '이미지',
  src: '/icon/arrow_filter.png',
  width: 14,
  height: 14,
})``;

export const FormButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.main};
  border-radius: 0px;
  padding-block: 7.5px !important;
  padding-inline: 14px;
  border: 1px solid ${colors.main} !important;
  cursor: pointer !important;

  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  &.blur {
    background-color: #e5f3ff !important;
    border-color: #e5f3ff !important;
    color: ${colors?.main} !important;
  }

  &.delete {
    background-color: #a49f9f !important;
    border-color: #a49f9f !important;
    color: #fff !important;
  }
`;

export const FormInput = styled(Input)`
  border-radius: 0px;
`;

export const FormDatePicker = styled(DatePicker)`
  border-radius: 0px;
`;

export const FormRadioGroup = styled(Radio)``;

export const FormRadioItem = styled(Radio)``;

export const FormCheckbox = styled(Checkbox)``;

export const FormPassword = styled(Input.Password)`
  border-radius: 0px;
`;

export const FormDesc = styled(Descriptions)`
  th.ant-descriptions-item {
    padding-bottom: 8px;
  }
`;

export const FormDescItem = styled(Descriptions.Item)``;

export const FormLabel = styled.span`
  ${({ required }) => {
    return (
      required &&
      css`
        &::before {
          content: '*';
          color: ${colors.red};
        }
      `
    );
  }}
`;
