import React from 'react';
import * as C from '../../style/mobile/PageSection.style';

const Mobile = ({ title, children, wrapperStyle, noHeader, bodyStyle }) => {
  return (
    <C.Wrapper style={wrapperStyle}>
      {noHeader ? null : (
        <C.Header>
          <C.Title>{title}</C.Title>
        </C.Header>
      )}
      <C.Body style={bodyStyle}>{children}</C.Body>
    </C.Wrapper>
  );
};

export default Mobile;
