import React from 'react';
import { Caution, PageContainer } from '../../components/layout/page';
import PageSection from '../../components/layout/PageSection';
import { FormSelect, SelectSuffixIcon } from '../../components/form';
import { FUTURES_TYPE_SELETION } from '../../common/defines';
import CustomCarousel from '../../components/carousel/CustomCarousel';
import { TRAIDING_CHANNERLS } from '../../common/dummy';
import ChannelItem from '../../components/item/ChannelItem';
import styled from 'styled-components';
import { colors } from '../../common/colors';
import MobileDetailHeader from '../../components/layout/MobileDetailHeader.js';
import { HORIZON_BETWEEN } from '../../common/align';
import { getCharts, getFutures } from '../../apis/futures/index.js';
import PeriodButtonSet from '../../components/filter/PeriodButtonSet/index.js';
import EarningRateChart from '../../components/chart/EarningRateChart.js';
import useScreen from '../../hooks/useScreen.js';
import dayjs from 'dayjs';

const SelectSuffix = styled.img.attrs({
  draggable: false,
  alt: '이이콘',
  width: 16,
  height: 8,
  src: '/icon/arrow_mobile_select.png',
})``;

const Diagnosis = styled.div`
  background-color: #eef3fa;
  padding-block: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & > .section {
    .label {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.84px;
    }
    .value {
      color: #42a5ff;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.84px;
    }
  }
`;

const Mobile = ({ videoIds }) => {
  const { is_mobile } = useScreen();
  const [futures, setFutures] = React.useState([]);
  const [year, setYear] = React.useState({ type: 'all', value: 'all' });
  const [name, setName] = React.useState('');
  const [chart, setChart] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [date, setDate] = React.useState('');
  const [position, setPosition] = React.useState({
    sign: '',
    weigth: 0,
  });

  const loadSign = async () => {
    try {
      // await getSign().then(())
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadFutures = async () => {
    try {
      await getFutures().then(({ data }) => {
        setFutures(data);

        setName(data?.[0]);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadDetail = async () => {
    try {
      setLoading(true);
      await getCharts({ name, year: year?.value }).then(({ data }) => {
        setChart(data);

        setDate(data?.[data?.length - 1]?.date);
      });
    } catch (error) {
      console.warn({ error });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadFutures();
  }, []);

  React.useEffect(() => {
    if (name) {
      loadDetail();
    }
  }, [name, year]);

  return (
    <PageContainer style={{ paddingBottom: 120 }}>
      <MobileDetailHeader
        wrapperStyle={{ marginBottom: 8 }}
        titleStyle={{ ...HORIZON_BETWEEN }}
        title={
          <>
            <FormSelect
              value={name}
              options={futures?.map((v) => ({
                key: v,
                label: v,
                value: v,
              }))}
              suffixIcon={<SelectSuffix />}
              style={{ width: is_mobile ? 200 : 230 }}
              noBorder={true}
              isHeader={true}
              onChange={(e) => {
                setName(e);
              }}
            />
            <span className={'date'}>{dayjs(date)?.format('YYYY.MM.DD')} 기준</span>
          </>
        }
      />

      <Diagnosis>
        <div className={'section'}>
          <span className={'label'}>방향:&nbsp;</span>
          <span className={'value'}>{position?.sign || '-'}</span>
        </div>
        <div className={'section'}>
          <span className={'label'}>비중:&nbsp;</span>
          <span className={'value'}>{`${position?.weight || 0}%`}</span>
        </div>
      </Diagnosis>

      <PeriodButtonSet
        wrapperStyle={{
          padding: '12px 4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          gap: 4,
        }}
        buttonStyle={{ flex: 1 }}
        data={year?.type}
        setData={(v) => setYear(v)}
      />

      <PageSection
        noHeader={true}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          aspectRatio: 1 / 0.81,
          marginBottom: 20,
        }}
      >
        <EarningRateChart
          data={chart}
          year={year}
          type={'future'}
          loading={loading}
          position={position}
          setPosition={setPosition}
        />
      </PageSection>

      <PageSection title={<span>AI 트레이딩 방송</span>} wrapperStyle={{ padding: '12px 16px 50px' }}>
        <CustomCarousel
          dataSource={videoIds}
          slidesToScroll={2}
          slidesToShow={2}
          renderItem={(item, index) => {
            return <ChannelItem item={item} index={index} />;
          }}
        />
      </PageSection>
      <Caution style={{ paddingTop: 11 }}>
        본 서비스는 AI 모델의 성과를 테스트하고 모니터링 하기 위한 목적으로 투자를 권유하지 않습니다. 또한, 제공되는
        모든 정보의 정확성, 완전성을 보장하지 않으며 어떠한 경우에도 정보 이용의 결과에 대한 책임을 지지 않습니다. 모든
        투자에 대한 책임은 본인에게 있습니다.
      </Caution>
    </PageContainer>
  );
};

export default Mobile;
