export const BOARD_INIT = {
  category: 'AI/퀀트 채용공고',
  content: '',
  id: 0,
  isFix: true,
  password: '',
  title: '',
  viewCount: 0,
  writer: '',
};
