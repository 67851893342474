import React from 'react';

const useScreen = () => {
  const [screenWidth, setScreenWidth] = React.useState(0);
  const [screenHeight, setScreenHeight] = React.useState(0);
  const is_mobile = React.useMemo(() => screenWidth <= 768, [screenWidth]);

  React.useEffect(() => {
    const handleSize = () => {
      const { innerWidth, innerHeight } = window ?? {};
      setScreenWidth(innerWidth);
      setScreenHeight(innerHeight);
    };
    window.addEventListener('resize', handleSize);
    handleSize();
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  return { screenWidth, screenHeight, is_mobile };
};

export default useScreen;
