import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';
import { getAPIHost, restApi } from '../../apis';
// import { fileApi, getFileHost } from '../../apis';

const TinyEditor = styled(Editor)``;

const CustomTinyEditor = ({ value, onChange, height = '623px', id = 'tinyEditor', placeholder = '' }) => {
  return (
    <TinyEditor
      apiKey={'54lb4b3dear0erpefg1qb4gerk6m6dl27iiyl1nbhfyvkdk2'}
      id={id}
      value={value}
      onEditorChange={(e) => {
        onChange(e);
      }}
      init={{
        height,

        plugins: [
          //   "advlist",
          //   "autolink",
          //   "lists",
          //   "link",
          //   "image",
          //   "charmap",
          //   "preview",
          //   "anchor",
          //   "searchreplace",
          //   "visualblocks",
          //   "code",
          //   "fullscreen",
          //   "insertdatetime",
          //   //   "media",
          'table',
          //   "code",
          //   "help",
          //   "wordcount",
          //   // 'a11ychecker',
          'image',
          //   // 'advcode',
          // 'autoresize',
        ],

        language: 'ko_KR',
        images_reuse_filename: true,
        async images_upload_handler(blobInfo) {
          const formData = new FormData();

          formData.append('file', blobInfo.blob());

          const { data } = await restApi
            .post('/api/files', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .catch((rej) => {
              console.warn({ rej });
            });

          return `${getAPIHost()}/api/files/${data.id}`;
        },
        placeholder,
        toolbar:
          'undo redo  ' +
          '| fontfamily fontsizeinput |' +
          'bold underline italic forecolor | alignleft aligncenter alignright alignjustify | ' +
          'link image table | bullist numlist outdent indent ' +
          ' | code charmap ',

        font_family_formats:
          '나눔고딕=nanum gothic; 나눔명조=nanum myeongjo; 나눔붓글씨=nanum brush script; 나눔펜글씨=nanum pen script; 검은고딕=black han sans;' +
          'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
      }}
    />
  );
};

export default CustomTinyEditor;
