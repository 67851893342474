import { Button } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../common/colors';
import { useNavigate } from 'react-router';
import useScreen from '../hooks/useScreen';

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.span`
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const SubTitle = styled.span`
  color: #898989;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const Btn = styled(Button)`
  width: 134px;
  height: 50px;
  background-color: ${colors.main};
  color: #fff !important;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-color: ${colors.main} !important;

  @media (max-width: 767px) {
    font-size: 13px;
    width: 100px;
    height: 40px;
  }

  ${({ white }) => {
    return (
      white &&
      css`
        background-color: #fff !important;
        border: 1px solid ${colors.main} !important;
        box-sizing: border-box !important;
        color: ${colors.main} !important;
      `
    );
  }}
`;

const NotFound = () => {
  const navigate = useNavigate();
  const { is_mobile } = useScreen();

  //   React.useEffect(() => {
  //     if (!is_mobile) {
  //       navigate('/consultation');
  //     }
  //   }, [is_mobile]);

  return (
    <Wrapper>
      <Box>
        <img src={'/icon/exclamation_triangle.png'} alt="아이콘" style={{ width: 173, height: 169, marginBottom: 8 }} />
        <Title>요청하신 페이지를 찾을 수 없습니다.</Title>
        <SubTitle>
          <span>이용에 불편을 드려 죄송합니다.</span>
          <br />
          <span>페이지의 주소가 변경 혹은 삭제되어</span>
          <br />
          <span>요청하신 페이지를 찾을 수 없습니다.</span>
          <br />
        </SubTitle>

        <ButtonWrapper>
          <Btn onClick={() => navigate(-1)} white>
            이전 화면으로
          </Btn>
          <Btn onClick={() => navigate('/consultation')}>홈으로</Btn>
        </ButtonWrapper>
      </Box>
    </Wrapper>
  );
};

export default NotFound;
