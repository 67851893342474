import React from 'react';
import useScreen from '../../hooks/useScreen';
import Mobile from './Mobile';
import Default from './Default';
import { shallowEqual, useSelector } from 'react-redux';

const BasicLayout = () => {
  const { is_mobile } = useScreen();

  const { principal } = useSelector((s) => s?.auth, shallowEqual);

  if (is_mobile) {
    return <Mobile principal={principal} />;
  } else {
    return <Default principal={principal} />;
  }
};

export default BasicLayout;
