import React from 'react';
import * as C from './CustomTabs.style';

const CustomTabs = ({ options, params, setParams }) => {
  return (
    <C.Wrapper>
      <C.StyledTabs
        value={params?.category}
        options={options}
        onChange={(e) => {
          setParams((prev) => ({
            ...prev,
            category: e,
          }));
        }}
      />
    </C.Wrapper>
  );
};

export default CustomTabs;
