import { Button } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: scroll;
`;

export const Btn = styled(Button)`
  border-radius: 0px;
  border: 1px solid #a49f9f;
  color: #a49f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  // max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active }) => {
    return (
      active &&
      css`
        background: var(--Light-Gray, #f1f1f1);
      `
    );
  }}

  ${({ is_mobile }) => {
    return is_mobile
      ? css`
          flex: 1;
          height: 32px;
        `
      : css``;
  }}
`;
