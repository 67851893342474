import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  border-bottom: 1px solid #c9c9c9;
  cursor: pointer;
`;

export const Title = styled.div`
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const Writer = styled.span`
  margin-right: 14px;
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

export const Hit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Icon = styled.img.attrs({
  draggable: false,
  alt: '아이콘',
  width: 20,
  height: 20,
  src: '/icon/eye.png',
})`
  margin-right: 2px;
`;

export const ViewCount = styled.span`
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

export const Date = styled.span`
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;
