import React from 'react';
import * as C from './CustomCarousel.style';

const CustomCarousel = ({ dataSource, renderItem = () => {}, slidesToShow = 5, slidesToScroll = 5 }) => {
  const carouselRef = React.useRef();

  return (
    <C.Wrapper>
      <C.StyledCarousel draggable={true} slidesToShow={slidesToShow} slidesToScroll={slidesToScroll} ref={carouselRef}>
        {dataSource?.map((v, i) => {
          return renderItem(v, i);
        })}
      </C.StyledCarousel>
      <C.Btn className="left" onClick={() => carouselRef.current.prev()}>
        <C.Arrow />
      </C.Btn>
      <C.Btn className="right" onClick={() => carouselRef.current.next()}>
        <C.Arrow />
      </C.Btn>
    </C.Wrapper>
  );
};

export default CustomCarousel;
