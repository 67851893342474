import React from 'react';
import * as C from './EarningRateChart.style';
import dayjs from 'dayjs';
import { type } from '@testing-library/user-event/dist/type';
import { Spin } from 'antd';
import useScreen from '../../../hooks/useScreen';

const EarningRateChart = ({
  data,
  year,
  hover,
  loading,
  type = 'port',
  position,
  setHover,
  setPosition = () => {},
}) => {
  const { is_mobile, screenWidth } = useScreen();
  const chartRef = React.useRef();
  const parentRef = React.useRef();
  let yearType = year?.type;
  if (yearType === 'all') {
    yearType = 'All';
  }
  const dataSource = React.useMemo(() => {
    const ai = [];
    const bm = [];
    const dates = [];
    if (type === 'port') {
      data?.forEach((v) => {
        ai.push(v?.[`pdx${yearType}`]?.toFixed(2));
        bm.push(v?.[`pdxb${yearType}`]?.toFixed(2));
        dates.push(v?.date);
      });
    } else {
      data?.forEach((v) => {
        ai.push(v?.[`idx${yearType}`]?.toFixed(2));
        bm.push(v?.[`idxb${yearType}`]?.toFixed(2));
        dates.push(v?.date);
      });
    }

    return { ai, bm, dates };
  }, [data]);

  React.useEffect(() => {
    if (!chartRef?.current || !hover || dataSource?.dates?.length === 0) return;
    const chart = chartRef?.current?.chart;
    const index = dataSource?.dates?.findIndex((v) => v === hover);
    parentRef?.current?.querySelector('.apexcharts-yaxistooltip').classList.remove('apexcharts-active');
    parentRef?.current?.querySelector('.apexcharts-xaxistooltip').classList.remove('apexcharts-active');
    parentRef?.current?.querySelector('.apexcharts-tooltip').classList.remove('apexcharts-active');
    if (index > -1) {
      const y = data?.find((v) => v?.date === hover)?.[`idx${yearType}`]?.toFixed(2);
      const yBm = data?.find((v) => v?.date === hover)?.[`idxb${yearType}`]?.toFixed(2);
      setPosition((prev) => ({
        ...prev,
        sign: data?.find((v) => v?.date === hover).sign,
        weight: data?.find((v) => v.date === hover)?.weight,
      }));

      chart.clearAnnotations();
      chart.addPointAnnotation({
        id: 'my-annotation',
        x: new Date(hover).getTime(),
        y,
        marker: {
          size: 6,
          fillColor: '#E85656',
          strokeColor: '#fff',
          strokeWidth: 2,
        },
        // label: {
        //   text: 'Lorem Ipsum'
        // },
      });
      parentRef?.current?.querySelector('.apexcharts-yaxistooltip').classList.add('apexcharts-active');

      parentRef?.current?.querySelector('.apexcharts-xaxistooltip').classList.add('apexcharts-active');
      parentRef?.current?.querySelector('.apexcharts-tooltip').classList.add('apexcharts-active');
      parentRef.current.querySelector('.apexcharts-xaxistooltip-text').innerText = dayjs(hover).format('YYYYMMDD');
      parentRef.current.querySelector('.apexcharts-yaxistooltip-text').innerText = y;
      const el = `<div class='custom_tooltip'>
              <div><span>AI 누적 수익률: <b class="custom_tooltip_ai">${
                dataSource?.ai?.length > 0 ? `${y}%` : '-'
              }</b></span></div>
              <div><span>BM 누적 수익률: <b class="custom_tooltip_bm">${
                dataSource?.bm?.length > 0 ? `${yBm}%` : '-'
              }</b></span></div>
              </div>`;
      if (!parentRef.current.querySelector('.apexcharts-tooltip').querySelector('.custom_tooltip')) {
        parentRef.current.querySelector('.apexcharts-tooltip').innerHTML = el;
      } else {
        parentRef.current.querySelector('.custom_tooltip_ai').innerText = `${y}%`;
        parentRef.current.querySelector('.custom_tooltip_bm').innerText = `${yBm}%`;
      }
      const xLeft =
        parentRef?.current?.querySelector('.apexcharts-point-annotations').getBoundingClientRect().x +
        parentRef?.current?.querySelector('.apexcharts-point-annotations').getBoundingClientRect().width / 2 -
        parentRef?.current?.querySelector('.apexcharts-canvas').getBoundingClientRect().x -
        parentRef?.current?.querySelector('.apexcharts-xaxistooltip').getBoundingClientRect().width / 2;
      const yTop =
        parentRef?.current?.querySelector('.apexcharts-point-annotations').getBoundingClientRect().y +
        parentRef?.current?.querySelector('.apexcharts-point-annotations').getBoundingClientRect().height / 2 -
        parentRef?.current?.querySelector('.apexcharts-canvas').getBoundingClientRect().y -
        parentRef?.current?.querySelector('.apexcharts-xaxistooltip').getBoundingClientRect().height / 2;

      parentRef.current.querySelector('.apexcharts-xaxistooltip').style.left = '' + xLeft + 'px';
      parentRef.current.querySelector('.apexcharts-xaxistooltip').style.top = '288.252px';
      parentRef.current.querySelector('.apexcharts-yaxistooltip').style.top = '' + yTop + 'px';
      parentRef.current.querySelector('.apexcharts-yaxistooltip').style.left = '8.64584px';

      const tooltipWidth = parentRef.current.querySelector('.apexcharts-tooltip').getBoundingClientRect().width;
      const tooltipLeft =
        parentRef.current.querySelector('.apexcharts-point-annotations').getBoundingClientRect().x +
        parentRef.current.querySelector('.apexcharts-point-annotations').getBoundingClientRect().width / 2 -
        parentRef.current.querySelector('.apexcharts-canvas').getBoundingClientRect().x;
      const tooltipTop =
        parentRef.current.querySelector('.apexcharts-point-annotations').getBoundingClientRect().y +
        parentRef.current.querySelector('.apexcharts-point-annotations').getBoundingClientRect().height / 2 -
        parentRef.current.querySelector('.apexcharts-canvas').getBoundingClientRect().y -
        parentRef.current.querySelector('.apexcharts-tooltip').getBoundingClientRect().height / 2;
      const overHalf =
        parentRef?.current.querySelector('.apexcharts-grid').getBoundingClientRect().width / 2 <
        parseInt(parentRef?.current.querySelector('.apexcharts-point-annotation-marker').getAttribute('cx'));
      // console.log(
      //   parentRef?.current.querySelector('.apexcharts-point-annotation-marker').getAttribute('cx'),
      //   parentRef?.current.querySelector('.apexcharts-grid').getBoundingClientRect().width / 2,
      // );
      // console.log({ tooltipWidth, overHalf });

      parentRef.current.querySelector('.apexcharts-tooltip').style.left =
        tooltipLeft + (overHalf ? -(tooltipWidth + 10) : 10) + 'px';
      parentRef.current.querySelector('.apexcharts-tooltip').style.top = tooltipTop + 'px';

      chart.addXaxisAnnotation({
        x: new Date(hover).getTime(),
        borderColor: '#E85656',
        strokeDashArray: 0,
        borderWidth: 2,
      });
      chart.addYaxisAnnotation({
        y,
        borderColor: '#E85656',
        strokeDashArray: 0,
        borderWidth: 2,
      });
    } else {
      chart.clearAnnotations();
    }
  }, [hover, dataSource, chartRef, parentRef]);

  React.useEffect(() => {
    if (chartRef?.current) {
      // console.log(chartRef?.current?.chart?.annotations.pointsAnnotations, '확인');
    }
  }, [chartRef?.current, parentRef?.current]);

  return (
    <C.Wrapper
      ref={parentRef}
      style={
        loading
          ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }
          : {}
      }
    >
      {loading ? (
        <Spin />
      ) : (
        <C.Chart
          is_mobile={is_mobile}
          type={'area'}
          height={380}
          ref={chartRef}
          series={[
            {
              data: dataSource?.ai,
              name: 'AI 누적 수익률',
              color: '#E85656',
            },
            {
              data: dataSource?.bm,
              name: 'BM 누적 수익률',
              color: '#cacaca',
            },
          ]}
          options={{
            grid: {
              yaxis: { lines: { show: true } },
              xaxis: { lines: { show: true } },
            },
            chart: {
              toolbar: false,
              id: 'earning',
              type: 'area',
              zoom: {
                enabled: false,
              },
              events: {
                mouseMove: function (event, chartContext, config) {
                  if (hover) setHover?.(undefined);
                  chartContext.clearAnnotations();
                },
              },
              selection: {
                enabled: true,
                type: 'xy',
                fill: {
                  color: '#A30606',
                  opacity: 0.0,
                },
                stroke: {
                  width: 3,
                  dashArray: 4,
                  color: '#A30606',
                  opacity: 0.0,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },

            stroke: {
              curve: 'smooth',
              width: 1.5,
            },

            yaxis: {
              tooltip: {
                enabled: true,
                offsetX: -20,
              },

              crosshairs: {
                show: true,
                opacity: 1,
                position: 'front',

                stroke: {
                  color: '#E85656',
                  width: 2,
                  dashArray: 0,
                },
              },
            },

            xaxis: {
              categories: dataSource?.dates,
              type: 'datetime',
              tickAmount: is_mobile ? 10 : 20,

              labels: {
                formatter: (value) => {
                  return dayjs(value).format('YYYYMMDD');
                },
                style: {
                  fontSize: 12,
                  colors: '#7d7d7d',
                },
              },

              crosshairs: {
                show: true,
                opacity: 1,
                position: 'front',
                stroke: {
                  color: '#E85656',
                  width: 2,
                  dashArray: 0,
                },
              },
            },

            tooltip: {
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                setPosition((prev) => ({
                  ...prev,
                  sign: data?.[dataPointIndex]?.sign,
                  weight: data?.[dataPointIndex]?.weight,
                }));

                return `<div class='custom_tooltip'>
              <div><span>AI 누적 수익률: <b class="custom_tooltip_ai">${
                series?.[0]?.length > 0 ? `${(series?.[0]?.[dataPointIndex] - 100).toFixed(2)}%` : '-'
              }</b></span></div>
              <div><span>BM 누적 수익률: <b class="custom_tooltip_bm">${
                series?.[1]?.length > 0 ? `${(series?.[1]?.[dataPointIndex] - 100).toFixed(2)}%` : '-'
              }</b></span></div>
              </div>`;
              },
            },
          }}
        ></C.Chart>
      )}
    </C.Wrapper>
  );
};

export default React.memo(EarningRateChart);
