import React from 'react';
import { PageContainer } from '../../components/layout/page';
import { Col, Row } from 'antd';
import PageSection from '../../components/layout/PageSection';
import TimeLine from '../../components/list/TimeLine';
import CustomCarousel from '../../components/carousel/CustomCarousel';
import { TRAIDING_CHANNERLS } from '../../common/dummy';
import ChannelItem from '../../components/item/ChannelItem';
import { FormButton, FormSelect, SelectSuffixIcon } from '../../components/form';
import { FUTURES_TYPE_SELETION } from '../../common/defines';
import { ALIGN_CENTER, HORIZON_BETWEEN, HORIZON_START } from '../../common/align';
import { getCharts, getFutures, getSign } from '../../apis/futures';
import dayjs from 'dayjs';
import PeriodButtonSet from '../../components/filter/PeriodButtonSet';
import EarningRateChart from '../../components/chart/EarningRateChart.js';
import useScreen from '../../hooks/useScreen.js';
import styled from 'styled-components';

const Position = styled.div`
  display: flex;
  align-items: center;
  & > div:first-child {
    margin-right: 16px;
  }

  & > div > .label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  & > div > .value {
    color: #2999ff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;

    &:nth-of-type(1) {
      margin-right: 16px;
    }
  }
`;

const Default = ({ videoIds }) => {
  const { screenWidth } = useScreen();
  const [futures, setFutures] = React.useState([]);
  const [year, setYear] = React.useState({ type: 'all', value: 'all' });
  const [name, setName] = React.useState('');
  const [chart, setChart] = React.useState([]);
  const [allTimeChart, setAllTimeChart] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hover, setHover] = React.useState(undefined); // { date: '', ai: '', bm: '' }
  const [date, setDate] = React.useState();
  const [position, setPosition] = React.useState({
    sign: '',
    weigth: 0,
  });

  const loadFutures = async () => {
    try {
      await getFutures().then(({ data }) => {
        setFutures(data);

        setName(data?.[0]);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadAllTImeChart = async () => {
    try {
      await getCharts({ name }).then(({ data }) => {
        setAllTimeChart(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const loadDetail = async () => {
    try {
      setLoading(true);
      await getCharts({ name, year: year?.value }).then(({ data }) => {
        setChart(data);

        setDate(test?.[data?.length - 1]?.date);
      });
    } catch (error) {
      console.warn({ error });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadFutures();
  }, []);

  React.useEffect(() => {
    if (name) {
      loadDetail();
      loadAllTImeChart();
    }
  }, [name, year]);

  const triggerHover = () => {};

  return (
    <PageContainer>
      <Row gutter={[24, 20]}>
        <Col span={screenWidth < 1400 ? 24 : 16}>
          <PageSection
            wrapperStyle={{ height: '100%' }}
            bodyStyle={{ height: '100%' }}
            title={
              <div style={{ ...HORIZON_BETWEEN }}>
                <div style={{ ...HORIZON_START }}>
                  <FormSelect
                    value={name}
                    options={futures?.map((v) => ({
                      key: v,
                      label: v,
                      value: v,
                    }))}
                    suffixIcon={<SelectSuffixIcon />}
                    style={{ width: 200 }}
                    noBorder={true}
                    isHeader={true}
                    onChange={(e) => {
                      setName(e);
                    }}
                  />
                  <Position>
                    <div>
                      <span className={'label'}>방향 : </span>
                      <span className={'value'}>{position?.sign || '-'}</span>
                    </div>
                    <div>
                      <span className={'label'}>비중 : </span>
                      <span className={'value'}>{`${position?.weight || 0}%`}</span>
                    </div>
                  </Position>
                </div>

                <div style={{ ...HORIZON_START }}>
                  <FormButton
                    onClick={() => setHover(date)}
                    style={{ width: 98, paddingInline: 0, marginRight: 10 }}
                    className={'blur'}
                  >
                    최근 일자 조회
                  </FormButton>
                  <span style={{ display: 'block', fontSize: 14, color: '#939393', fontWeight: '500' }}>{`${dayjs(
                    dayjs(date).format('YYYY.MM.DD'),
                  )?.format('YYYY.MM.DD')} 기준`}</span>
                </div>
              </div>
            }
          >
            <PeriodButtonSet
              wrapperStyle={{
                padding: '30px 13px 20px',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 7,
              }}
              data={year?.type}
              setData={(v) => {
                setYear(v);
                setHover(undefined);
              }}
            />
            <EarningRateChart
              setHover={setHover}
              data={chart}
              year={year}
              type={'future'}
              loading={loading}
              hover={hover}
              setPosition={setPosition}
            />
          </PageSection>
        </Col>
        <Col span={screenWidth < 1400 ? 24 : 8}>
          <PageSection title={<span className={'title'}>금융 시장 주요 뉴스</span>} highlight={true}>
            <TimeLine setHover={setHover} chart={chart} year={year} setYear={setYear} allTimeChart={allTimeChart} />
          </PageSection>
        </Col>

        <Col span={24}>
          <PageSection
            title={<span className={'title'}>실시간 AI 트레이딩 채널</span>}
            bodyStyle={{ padding: '33px 50px 57px' }}
          >
            <CustomCarousel
              dataSource={videoIds}
              renderItem={(item, index) => {
                return <ChannelItem item={item} index={index} />;
              }}
            />
          </PageSection>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Default;
