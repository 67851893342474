import ReactApexChart from 'react-apexcharts';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Chart = styled(ReactApexChart)`
  .apexcharts-legend {
    gap: ${({ is_mobile }) => {
      return is_mobile ? '27px' : '50px';
    }};

    ${({ is_mobile }) => {
      return is_mobile ? css`` : css``;
    }}
  }

  .apexcharts-tooltip {
    border-radius: 0px;
  }

  .apexcharts-xaxistooltip-bottom:after,
  .apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: #e85656;
  }
  .apexcharts-yaxistooltip-left.apexcharts-theme-light:after,
  .apexcharts-yaxistooltip-left.apexcharts-theme-light:before {
    border-left-color: #e85656;
  }

  .apexcharts-xaxistooltip {
    background-color: #e85656 !important;
    padding: 5px !important;
    border-color: #e85656;

    color: #fff !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.409px !important;
  }

  .apexcharts-xaxistooltip-text {
    color: #fff !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.409px !important;
  }

  .apexcharts-yaxistooltip {
    background-color: #e85656 !important;
    padding: 5px !important;
    border-color: #e85656;
  }

  .apexcharts-yaxistooltip-text {
    color: #fff !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: -0.409px !important;
  }

  .apexcharts-legend-marker {
    width: 12px;
    height: 12px;
    border-radius: 0px !important;
    margin-right: 10px;
  }

  .apexcharts-legend-text {
    color: #212121;
    font-weight: 500 !important;
    line-height: normal;
    font-size: ${({ is_mobile }) => {
      return is_mobile ? '13px !important' : '16px !important';
    }};
  }

  .custom_tooltip {
    padding: 5px;
    border-radius: 0px;
    overflow: hidden;
    border: 2px solid #e85656;
    background-color: #fff;
  }
`;
