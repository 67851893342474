import React from 'react';
import * as C from './ModelView.style';

const ModelView = ({ wrapperStyle, target }) => {
  return (
    <C.Wrapper style={{ ...wrapperStyle }}>
      <C.Header>
        <C.Title>AI 모델 판단</C.Title>
      </C.Header>

      <C.Summary style={{ marginBottom: 50 }}>
        <div className={'sentence'}>
          <span>∙</span>
          <span>{target?.comment1}</span>
        </div>
        <div className={'sentence'}>
          <span>∙</span>
          <span>{target?.comment2}</span>
        </div>
      </C.Summary>

      <C.Img src={`/img/model${target?.picType1}.png`} />
    </C.Wrapper>
  );
};

export default ModelView;
