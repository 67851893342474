import { restApi } from '..';

export const STOCK_URL = '/api/stock';

export const getStocks = async () => {
  try {
    const result = await restApi.get(`${STOCK_URL}`);

    return result;
  } catch (error) {
    console.warn('getStocks with error', { error });
    throw error;
  }
};

export const getStockDetail = async (id) => {
  try {
    const result = await restApi.get(`${STOCK_URL}/${id}`);

    return result;
  } catch (error) {
    console.warn('getStocks with error', { error });
    throw error;
  }
};
