import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../common/colors';

export const Wrapper = styled.div``;

export const Sentence = styled.div`
  color: #444;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  display: flex;

  & > span:nth-of-type(1) {
    margin-right: 10px;
  }

  & b {
    color: ${colors.red};
  }
`;
