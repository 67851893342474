import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import BasicLayout from './nav/BasicLayout';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './data';
import { me } from './data/auth';
import { getToken } from './apis';
import { shallowEqual } from 'react-redux';

const Container = React.memo(() => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const loadInit = async () => {
      const { access_token } = getToken();

      await dispatch(
        me({
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }),
      );
    };

    loadInit();
  }, [dispatch]);

  return <BasicLayout />;
});

const App = (props) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
