import { Table } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const AxiosTable = styled(Table)`
  & * {
    border-radius: 0px !important;
  }

  .ant-table-row.is_top {
    background-color: #e4f0ff;
  }

  .ant-table-cell {
    font-size: 14px;
    font-weight: 500 !important;
  }

  th.ant-table-cell {
    padding-block: 7.5px !important;
    background-color: ${({ light }) => {
      return light ? 'rgba(66, 165, 255, 0.05) !important' : '#ececec !important';
    }};
  }

  td.ant-table-cell {
    cursor: pointer;
    padding-block: 7.5px !important;
    &.left {
      text-align: left !important;
    }
  }
`;
