import { List } from 'antd';
import styled from 'styled-components';

export const StyledList = styled(List)`
  .ant-list-item {
    padding: 0px !important;
  }
`;

export const Item = styled.div`
  display: flex;
  cursor: pointer;

  color: #000;
  font-size: ${({ is_mobile }) => {
    return is_mobile ? '14px' : '18px';
  }};
  font-weight: 500;

  line-height: normal;

  & > .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
