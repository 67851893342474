import React from 'react';
import { Body, Container } from '../../components/style/mobile/BasicLayout.style';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ROUTE_PAGES } from '../../hooks/useRoutes';
import { Footer } from 'antd/es/layout/layout';
import BottomNavigation from '../../components/widget/BottomNavigation';
import NotFound from '../../pages/NotFound';

const Mobile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Container>
      <Body>
        <Routes>
          {ROUTE_PAGES?.map((v) => {
            return (
              <>
                <Route key={`ROUTE_CONTENT_${v?.title}`} Component={v?.screen} path={v?.path}></Route>
              </>
            );
          })}
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </Body>
      <BottomNavigation location={location} navigate={navigate} />
    </Container>
  );
};

export default Mobile;
