import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.span`
  color: #42a5ff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.08px;
`;

export const Summary = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.84px;

  & > .sentence {
    display: flex;
  }
`;

export const Img = styled.img.attrs({ draggable: false, alt: '이미지', width: '100%' })`
  //   margin-top: 50px;

  aspect-ratio: 1/ 0.34;
`;
