import React from 'react';
import { PageContainer } from '../../../components/layout/page';
import PageSection from '../../../components/layout/PageSection';
import {
  FormButton,
  FormCheckbox,
  FormDesc,
  FormDescItem,
  FormInput,
  FormLabel,
  FormPassword,
  FormRadioGroup,
  FormRadioItem,
  FormSelect,
  SelectSuffixIcon,
} from '../../../components/form';
import { BOARD_CATEGORIES } from '../../../common/defines';
import { produce } from 'immer';
import CustomTinyEditor from '../../../components/editor/CustomTinyEditor';
import { Col, Form, Row, message } from 'antd';
import BoardList from '../../../components/list/BoardList';
import { HORIZON_END } from '../../../common/align';
import { getBoardCategories, getBoardDetail, postBoard, putBoard } from '../../../apis/community';
import { deleteTags } from '../../../utils';
import { useNavigate } from 'react-router';

const Default = ({ requestBody, setRequestBody, id }) => {
  const navigate = useNavigate();
  const [category, setCategory] = React.useState();
  const [passwordCheck, setPasswordCheck] = React.useState('');

  const loadData = async () => {
    try {
      await getBoardDetail(id).then(({ data }) => {
        setRequestBody(
          produce((draft) => {
            draft.category = data?.category;
            draft.content = data?.content;
            draft.isFix = data?.isFix;
            draft.title = data?.title;
            draft.writer = data?.writer;
          }),
        );
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const onSubmit = async () => {
    try {
      if (!requestBody?.writer) {
        message.error('작성자를 입력하세요.');
        return;
      }
      if (!requestBody?.title) {
        message.error('제목을 입력하세요.');
        return;
      }
      if (!deleteTags(requestBody?.content)) {
        message.error('내용을 입력하세요.');
        return;
      }
      if (!id && requestBody?.password && passwordCheck !== requestBody?.password) {
        message.error('비밀번호가 일치하지 않습니다.');
        return;
      }

      if (id) {
        await putBoard(id, requestBody)
          .then((res) => {
            message.success('수정이 완료되었습니다.');
            navigate('/community');
          })
          .catch((rej) => {
            message.error('수정을 실패했습니다.');
          });
      } else {
        await postBoard(requestBody)
          .then((res) => {
            message.success('등록이 완료되었습니다.');
            navigate('/community');
          })
          .catch((rej) => {
            message.error('등록을 실패했습니다.');
          });
      }
    } catch (error) {
      console.warn({ error });
    }
  };

  const onInputChange = (e) => {
    setRequestBody(
      produce((draft) => {
        draft[e.target.name] = e.target.value;
      }),
    );
  };

  const loadCategories = async () => {
    try {
      await getBoardCategories().then(({ data }) => {
        setCategory(data);
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  const form_data = [
    {
      label: <FormLabel>상단 고정 여부</FormLabel>,
      content: (
        <FormCheckbox
          checked={requestBody?.isFix}
          onChange={(e) => {
            setRequestBody((prev) => ({
              ...prev,
              isFix: e.target.checked,
            }));
          }}
        />
      ),
    },
    {
      label: <FormLabel required>작성자</FormLabel>,
      content: (
        <FormInput
          placeholder={'작성자를 입력하세요.'}
          value={requestBody?.writer}
          name={'writer'}
          onChange={onInputChange}
        />
      ),
    },
    {
      label: <FormLabel required>제목</FormLabel>,
      content: (
        <FormInput
          placeholder={'제목을 입력하세요.'}
          value={requestBody?.title}
          name={'title'}
          onChange={onInputChange}
        />
      ),
    },
    {
      label: <FormLabel required>내용</FormLabel>,
      content: (
        <CustomTinyEditor
          height={575}
          value={requestBody?.content}
          onChange={(e) => {
            setRequestBody(
              produce((draft) => {
                draft.content = e;
              }),
            );
          }}
        />
      ),
    },
    {
      label: <FormLabel>잠금 비밀번호</FormLabel>,
      content: (
        <FormPassword
          placeholder={'잠금 비밀번호'}
          value={requestBody?.password}
          name={'password'}
          onChange={onInputChange}
        />
      ),
    },
    {
      label: <FormLabel required={requestBody?.password}>비밀번호 확인</FormLabel>,
      content: (
        <FormPassword
          placeholder={'비밀번호 확인'}
          value={passwordCheck}
          onChange={(e) => setPasswordCheck(e.target.value)}
        />
      ),
    },
  ];

  React.useEffect(() => {
    loadCategories();
  }, []);

  React.useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  return (
    <PageContainer>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <PageSection
            title={<span className={'title'}>인기글 TOP 10</span>}
            highlight={true}
            bodyStyle={{ padding: '30px 20px' }}
            wrapperStyle={{ height: '100%' }}
          >
            <BoardList />
          </PageSection>
        </Col>
        <Col span={18}>
          <PageSection
            bodyStyle={{ padding: '14px 12px 80px' }}
            title={
              <FormSelect
                suffixIcon={<SelectSuffixIcon />}
                style={{ width: 159 }}
                options={category?.map((v) => ({
                  key: v,
                  label: v,
                  value: v,
                }))}
                value={requestBody?.category}
                onChange={(e) => {
                  setRequestBody(
                    produce((draft) => {
                      draft.type = e;
                    }),
                  );
                }}
              />
            }
          >
            <FormDesc bordered labelStyle={{ width: 200 }}>
              {(id ? form_data?.slice(0, form_data?.length - 1) : form_data)?.map((v, i) => {
                return (
                  <FormDescItem key={`BOARD_FORM_${i}`} label={v?.label} span={4}>
                    {v?.content}
                  </FormDescItem>
                );
              })}
            </FormDesc>

            <div style={{ marginTop: 20, ...HORIZON_END }}>
              <FormButton style={{ width: 99, height: 32 }} onClick={onSubmit}>
                저장
              </FormButton>
            </div>
          </PageSection>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Default;
