import React from 'react';
import * as C from '../../style/default/PageSection.style';

const Default = ({ title, children, wrapperStyle, bodyStyle, noHeader, highlight = false, titleStyle }) => {
  return (
    <C.Wrapper style={wrapperStyle}>
      {noHeader ? null : (
        <C.Header highlight={highlight}>
          <C.Title highlight={highlight} style={{ ...titleStyle }}>
            {title}
          </C.Title>
        </C.Header>
      )}
      <C.Body style={bodyStyle}>{children}</C.Body>
    </C.Wrapper>
  );
};

export default Default;
