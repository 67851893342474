import React from 'react';
import * as C from './BoardItem.style';
import dayjs from 'dayjs';
import styled from 'styled-components';

const Volume = styled.img.attrs({ draggable: false, src: '/icon/volume.png', alt: '아이콘', width: 18, height: 18 })`
  margin-right: 10px;
`;

const BoardItem = ({ item, index, onClick }) => {
  console.log(item?.isFix, item?.title, 'title');
  return (
    <C.Wrapper onClick={onClick}>
      <C.Title style={{ display: 'flex', alignItems: 'center' }}>
        {item?.is_top ? <Volume /> : null}
        {item?.title}
      </C.Title>

      <C.Info>
        <C.Writer>{item?.writer}</C.Writer>

        <C.Hit>
          <C.Icon />
          <C.ViewCount>{item?.viewCount}</C.ViewCount>
        </C.Hit>

        <C.Date>{dayjs(item?.createdAt)?.format('YYYY.MM.DD')}</C.Date>
      </C.Info>
    </C.Wrapper>
  );
};

export default BoardItem;
